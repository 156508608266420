import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import ReCAPTCHA from "react-google-recaptcha";
import * as AutorizacaoClienteStore from '../../store/AutorizacaoCliente';
import '../Layout.css';
import './style.css';

import Loading from '../Loading'

type AutorizacaoClienteProps =
    AutorizacaoClienteStore.AutorizacaoClienteState
    & typeof AutorizacaoClienteStore.actionCreators
    & RouteComponentProps<{ token: string }>;

type IdentificacaoState = {
    numeroCPF?: string,
    codigoValidacao?: string,
    isCaptchaLoaded: boolean
}

type QueryStringToken = {
    t?: string;
}

class Identificacao extends React.PureComponent<AutorizacaoClienteProps, IdentificacaoState> {
    captcha: React.RefObject<ReCAPTCHA>;
    constructor(props: Readonly<AutorizacaoClienteProps>) {
        super(props);
        this.state = { isCaptchaLoaded: false };
        this.captcha = React.createRef();
    }

    public componentDidMount() {
        const params: QueryStringToken | undefined = queryString.parse(this.props.location.search);
        if (!this.props.token && params && params.t) {
            this.props.history.push(`/${params['t']}`);
        }
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public componentWillUnmount() {
    }

    private ensureDataFetched() {
        const token = this.props.match.params.token || undefined;
        if (token) {
            this.props.requestAutorizacaoCliente(token);
        }
    }

    public renderValidarCPF() {
        return (
            <div className="mb-3">
                <label className="form-label">Digite os 5 primeiros números do seu CPF:</label>
                <NumberFormat type="text" format="#####" mask="_" className="inputs" id="cpf" placeholder="Digite aqui" autoComplete="off" pattern="\d*" tabIndex={1}
                    value={this.state.numeroCPF} onChange={(e) => this.setState({ numeroCPF: e.target.value })} />
            </div>
        );
    }

    public renderValidarSMS() {
        return (
            <div className="mb-3">
                <label className="form-label">Agora digite o código que você anotou do SMS:</label>
                <NumberFormat type="text" format="######" mask="_" className="inputs" id="sms" placeholder="Digite aqui" autoComplete="off" pattern="\d*" tabIndex={2}
                    value={this.state.codigoValidacao} onChange={(e) => this.setState({ codigoValidacao: e.target.value })} />
            </div>
        );
    }

    public renderCaptcha() {
        return (
            <ReCAPTCHA
                size="invisible"
                hl="pt-BR"
                ref={this.captcha}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                onChange={this.v2Callback}
                asyncScriptOnLoad={this.v2Loaded}
            />
        );
    }
    private v2Loaded = async () => {
        if (!this.state.isCaptchaLoaded && this.captcha && this.captcha.current) {
            try {
                await this.captcha.current.render();
            } catch { }
        }
    }
    private v2Render = () => {
        if (!this.state.isCaptchaLoaded) {
            this.setState({ isCaptchaLoaded: true });
        }
    }
    private v2Callback = (recaptchaToken: any): void => {
        if (typeof recaptchaToken === 'string' && recaptchaToken) {
            this.validateData();
        }
    };
    private expiredCallback = () => {
    }
    private callExecute = () => {
        if (this.captcha && this.captcha.current) {
            try {
                this.captcha.current.execute();
            } catch { }
        } else {
            this.validateData();
        }
    }

    private validateData = () => {
        if (this.props.token) {
            let numeroCPF = this.state.numeroCPF ? this.state.numeroCPF : "0";
            let codigoValidacao = this.state.codigoValidacao ? this.state.codigoValidacao : "0";
            this.props.requestAutorizacaoPendente(this.props.token, numeroCPF, codigoValidacao);
            if (this.captcha && this.captcha.current) {
                //await this.captcha.current.reset();
            }
            this.props.history.push(`/informacoes-contrato/${this.props.token}`);
        }
    }

    private checkDenyContract = () => {
        const token: string = this.props.token ? this.props.token : '';
        this.props.requestAutorizacaoNegada(token, 2, `/${this.props.token}`);
        this.props.history.push(`/contrato-cancelado/${this.props.token}`);
    }

    //Render
    public render() {
        return (
            <React.Fragment>
                {this.props.isLoading && <>
                    <Loading />
                </>}
                {!this.props.isLoading && <>
                    {this.props.data && this.props.data.indicadorErro === 0 && this.props.data.indicadorExpirado && <Redirect to={`/link-invalido/${this.props.token}`} />}
                    {this.props.data && this.props.data.indicadorCaptcha === 3 && <Redirect to={`/link-invalido/${this.props.token}`} />}

                    {this.props.data && this.props.data.indicadorErro === 0 && !this.props.data.indicadorExpirado && <>
                        <div className="text-center card-title">
                            <h3 className="title">Vamos continuar!</h3>
                        </div>
                        <div className="mt-3" />
                        <form>
                            {this.props.data && this.props.data.indicadorValidarCPF && this.renderValidarCPF()}
                            {this.props.data && this.props.data.indicadorCodigoValidacao && this.renderValidarSMS()}
                            {this.props.data && this.props.data.indicadorCaptcha !== undefined && this.props.data.indicadorCaptcha > 0 && this.props.data.indicadorCaptcha < 3 && this.renderCaptcha()}

                            <button type="submit" className="btn btn-primary" value="submit-validation" onClick={(e) => { e.preventDefault(); this.callExecute(); }} tabIndex={3}>Continuar</button>
                            <button type="button" className="btn btn-cancel" onClick={this.checkDenyContract} tabIndex={4}>Desistir</button>
                        </form>
                    </>}

                    {this.props.data && this.props.data.indicadorErro !== undefined && this.props.data.indicadorErro !== 0 && <>
                        <div className="text-center card-title">
                            <h3 className="title">
                                <FontAwesomeIcon icon={["far", "times-circle"]} /> {this.props.data && this.props.data.descricaoCabecalho}
                            </h3>
                        </div>
                        <div className="mt-3 mb-3">
                            {this.props.data && this.props.data.descricaoErro}
                        </div>
                    </>}
                </>}
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.autorizacaoCliente,
    AutorizacaoClienteStore.actionCreators
)(Identificacao as any);
