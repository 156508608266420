import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as AutorizacaoClienteStore from '../../store/AutorizacaoCliente';
import '../Layout.css';

import Loading from '../Loading'
import { GetAutorizacaoPendenteCampos, PostInputAutorizacaoConfirmada } from '../../store/autorizacaoClienteTypes';
import { UnregisterCallback } from 'history';
import WebcamStreamCapture from '../SelfieCam';

type AutorizacaoPendenteProps =
    AutorizacaoClienteStore.AutorizacaoPendenteState
    & typeof AutorizacaoClienteStore.actionCreators
    & RouteComponentProps<{ token: string, numeroCPF: string, codigoValidacao: string }>;

type DadosContratoState = {
    currentPage?: number,
    currentAuth?: number,
    checkedYes?: boolean,
    checkedNo?: boolean,
    cartaoDownloaded?: boolean,
    emprestimoDownloaded?: boolean,
    checkedTerms?: boolean,
    showAlertDownload?: boolean,
    showAlertTerms?: boolean,
    showAlertAuth?: boolean,
    contracts?: contractsType2[],
    modalAuthOpen?: boolean,
    modalTermsOpen?: boolean,
    modalPrivacyOpen?: boolean,
    modalTermoConsentimentoOpen?: boolean;
    isDoneSelfie?: boolean,
    showBackArrow: boolean,
    idOnBase?: number,
    yada: number,
    currentOperacao?: number,
}

type contractsType2 = {
    id?: number,
    checked: boolean,
    showAlert?: boolean
}

class DadosContrato extends React.PureComponent<AutorizacaoPendenteProps, DadosContratoState> {
    currentPathname: string;
    currentSearch: string;
    unregisterHistoryListener: UnregisterCallback | null;
    constructor(props: Readonly<AutorizacaoPendenteProps>) {
        super(props);
        this.state = {
            checkedTerms: false,
            currentPage: this.props.currentPage !== undefined ? this.props.currentPage : 0,
            currentOperacao: this.props.currentOperacao !== undefined ? this.props.currentOperacao : 1,
            currentAuth: this.props.currentAuth !== undefined ? this.props.currentAuth : 0,
            isDoneSelfie: this.props.isDoneSelfie !== undefined ? this.props.isDoneSelfie : undefined,
            showBackArrow: true,
            yada: 0
        };
        if (this.props.token) {
            this.props.getSelfie(this.props.token);
        }
        this.currentPathname = '';
        this.currentSearch = '';
        this.unregisterHistoryListener = null;
    }

    public componentDidMount() {
        this.ensureDataFetched();
        if (this.props.data && this.props.data.indicadorErro === 0 && this.props.data.campos && this.state.contracts === undefined) {
            this.mountContracts();
        }
        if (this.unregisterHistoryListener === null) {
            this.unregisterHistoryListener = this.props.history.listen((newLocation, action) => {
                if (action === "PUSH") {
                    if (newLocation.pathname !== this.currentPathname || newLocation.search !== this.currentSearch) {
                        this.currentPathname = newLocation.pathname;
                        this.currentSearch = newLocation.search;
                        this.props.history.push({
                            pathname: newLocation.pathname,
                            search: newLocation.search
                        });
                    }
                } else {
                    let cp: number = this.state.currentPage !== undefined ? this.state.currentPage : 0;
                    let ca: number = this.state.currentAuth !== undefined ? this.state.currentAuth : 0;
                    if (!this.state.modalPrivacyOpen && !this.state.modalTermsOpen && !this.state.modalAuthOpen) {
                        if (this.state.currentPage === 1) {
                            if (this.state.currentAuth === 0) {
                                cp = 0;
                                ca = 0;
                            } else {
                                ca = this.state.currentAuth !== undefined ? this.state.currentAuth - 1 : 0;
                            }
                        } else if (this.state.currentPage !== undefined && this.state.currentPage > 1) {
                            cp = this.state.currentPage - 1;
                        }
                    }
                    this.props.setNavPendente(cp, ca);
                    this.props.history.go(1);
                }
            });
        }
    }
    public componentWillUnmount() {
        // if (this.unregisterHistoryListener) {
        //     this.unregisterHistoryListener();
        // }
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
        this.props.setNavPendente(this.state.currentPage !== undefined ? this.state.currentPage : 0, this.state.currentAuth !== undefined ? this.state.currentAuth : 0);
    }

    private ensureDataFetched() {
        if(this.props.data && this.props.data.indicadorErro === 0 && (!this.props.data.campos || this.props.data.campos.length <= 0) && this.state.currentPage != 1){
            this.mountContracts();
            this.jumpTo(1);
        }
        if (this.props.data && this.props.data.indicadorErro === undefined && !this.props.isLoading) {
            const token = this.props.match.params.token || undefined;
            if (token) {
                //this.props.history.push(`/${token}`);
            }
        }
    }

    //Dados do Contrato

    public renderSubtitle = () => {
        const subtitle = this.props.data && this.props.data.campos && this.props.data.campos.filter((c) => c.identificadorTipoCampo === 1 && c.identificadorOperacao === this.state.currentOperacao).pop();
        return (<>
            <div className="d-flex justify-content-start flex-column align-items-start detalhes">
                <label>{subtitle ? subtitle.valorCampo : `subtitle not found`}</label>
            </div>
        </>);
    };

    public renderHighlighted = () => {
        return (<>
            {this.props.data && this.props.data.campos && this.props.data.campos.filter((c) => c.identificadorTipoCampo === 2 && c.identificadorOperacao === this.state.currentOperacao).map((c, index) => (
                <div className="fundo" key={index}>
                    <div className="row">
                        <div className="col text-center">
                            <label className="camposTitle">{c.descricaoCampo}</label>
                            <div className="w-100"></div>
                            <div className="d-flex justify-content-start flex-column align-items-center ">
                                <label className="campoDestaque">{c.valorCampo}</label>
                                {c.descricaoComplementarCampo && c.descricaoComplementarCampo.trim() !== '' && (
                                    <label className="campoComplementar">{c.descricaoComplementarCampo}</label>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>);
    }

    public renderRegular = () => {
        return (<>
            <div className="d-flex justify-content-start flex-column align-items-start detalhes">
                {this.props.data && this.props.data.campos && this.props.data.campos.filter((c) => c.identificadorTipoCampo === 3 && c.identificadorOperacao === this.state.currentOperacao).map((c, index) => (
                    <div className="row" key={index}>
                        <div className="col">
                            <label className="camposTitle">{c.descricaoCampo}</label>
                            <div className="w-100"></div>
                            {c.valorCampo && c.valorCampo.indexOf('<b') > -1 && c.valorCampo.indexOf('<a') === -1 && c.valorCampo.indexOf('<script') === -1 ?
                                (<label className="camposValue" dangerouslySetInnerHTML={{ __html: c.valorCampo }}></label>) :
                                (<label className="camposValue">{c.valorCampo}</label>)
                            }
                            {c.descricaoComplementarCampo && c.descricaoComplementarCampo.trim() !== '' && (
                                <label className="camposComplementarValue">{c.descricaoComplementarCampo}</label>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>);
    }

    //Dados Adicionais
    public renderAuthNav = () => {
        const currItem = this.state.currentAuth ? this.state.currentAuth + 1 : 1;
        const totalItems = this.props.data && this.props.data.textosAutorizacoes ? this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 1)).length + 1 : 1;
        let steps = [];
        for (let x = 0; x < totalItems; x++) {
            steps.push(x);
        }
        return (<>
            {(this.state.isDoneSelfie === undefined || this.state.isDoneSelfie === true) && <>
                <div className="mb-3">
                    <label className="step-counter">Etapa {currItem} de {totalItems}</label>
                    <div>
                        {steps.map((x) => (
                            <div key={x} className={`btn-indicator` + (x + 1 <= currItem ? ` active` : ``)}></div>
                        ))}
                    </div>
                </div>
            </>}
        </>);
    }

    private getTermosConsentimento = (isAdesao?: boolean) => {
        if (isAdesao) {
            return <> e <button className="anchor" onClick={() => { this.setState({ modalTermoConsentimentoOpen: true }) }}>Termos de Consentimento</button></>;
        }
        return "";
    }

    private getButtonVerContrato = (isMultiplosContratos?: boolean, documentUrl?: string ) => {
        if(!isMultiplosContratos)
            return <a href={documentUrl} target="_blank" rel="noopener noreferrer" type="button" className="btn btn-contrato" onClick={() => { this.setState({ emprestimoDownloaded: true , showAlertDownload: false }) }}>Ver meu contrato</a>
        else {
            return (
                <>
                    <a href={documentUrl} target="_blank" rel="noopener noreferrer" type="button" className="btn btn-contrato" onClick={() => {this.openContract(true)}}>Ver meu contrato de Empréstimo</a>
                    <a href='https://bancomercantil.com.br/BeneficiarioINSS/Cartoes/cartao-consignado-mais/Documents/contrato_cartoes.pdf' target="_blank" rel="noopener noreferrer" type="button" className="btn btn-contrato" onClick={() => {this.openContract(false)}}>Ver meu contrato de Cartão Consignado</a>
                </>
            );
        }
    }

    public renderAuthorization = () => {
        const index: number = this.state.currentAuth ? this.state.currentAuth : 0;
        const textoAutorizacao = this.props.data && this.props.data.textosAutorizacoes && this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 1))[index];
        const checkedYes: boolean = textoAutorizacao && textoAutorizacao.valor !== undefined ? textoAutorizacao.valor : false;
        const checkedNo: boolean = textoAutorizacao && textoAutorizacao.valor !== undefined ? !textoAutorizacao.valor : false;
        const authPageNumber: number = this.props.data && this.props.data.textosAutorizacoes ? this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 1)).length : 0;
        if (index < authPageNumber && textoAutorizacao) {
            return (<>
                <div className="carousel-caption  d-md-block">
                    <h5>{textoAutorizacao.conteudoTexto}</h5>
                </div>
                <div className={"form-check " + (checkedYes ? "blue" : "gray")} id="botaoSim" onClick={() => { this.checkValue(true); }}>
                    <input className="form-check-input" type="radio" name="flexRadioDefaultEtapa1" id="flexRadioDefault1" checked={checkedYes} onChange={() => { }} />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">Sim</label>
                </div>
                <div className={"form-check " + (checkedNo ? "blue" : "gray")} id="botaoNao" onClick={() => { this.checkValue(false); }}>
                    <input className="form-check-input" type="radio" name="flexRadioDefaultEtapa1" id="flexRadioDefaultNao1" checked={checkedNo} onChange={() => { }} />
                    <label className="form-check-label" htmlFor="flexRadioDefaultNao1">Não</label>
                </div>
                {textoAutorizacao.conteudoTermoAutorizacao && <>
                    <div>
                        <button type="button" className="anchor" onClick={() => { this.setState({ modalAuthOpen: !this.state.modalAuthOpen }) }}>{textoAutorizacao.conteudoExplicacao}</button>
                    </div>
                    <Modal isOpen={this.state.modalAuthOpen} toggle={() => { this.setState({ modalAuthOpen: !this.state.modalAuthOpen }) }}>
                        <ModalHeader toggle={() => { this.setState({ modalAuthOpen: !this.state.modalAuthOpen }) }}>Termo de Autorização</ModalHeader>
                        <ModalBody>
                            <p dangerouslySetInnerHTML={{ __html: textoAutorizacao.conteudoTermoAutorizacao.replace(/\n/gi, '<br />') }}></p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => { this.setState({ modalAuthOpen: false }) }}>Fechar</Button>
                        </ModalFooter>
                    </Modal>
                </>}
                {!textoAutorizacao.conteudoTermoAutorizacao && textoAutorizacao.conteudoExplicacao !== undefined && <div dangerouslySetInnerHTML={{ __html: textoAutorizacao.conteudoExplicacao }}></div>}
                <div className="d-flex justify-content-around align-items-center buttons mb-4">
                    <button className="btn back-btn" type="button" onClick={this.jumpPrevStep}>
                        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M23.146 11L9.06153 11L15.5309 5.41L13.8875 4L4.62903 12L13.8875 20L15.5193 18.59L9.06153 13L23.146 13L23.146 11Z" fill="#164194"></path>
                        </svg>
                        Voltar
                    </button>
                    <button className="btn continue-btn" type="button" onClick={this.checkValueSelected}>
                        Continuar
                        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.83983 13L18.9243 13L12.4549 18.59L14.0983 20L23.3568 12L14.0983 4L12.4665 5.41L18.9243 11L4.83983 11L4.83983 13Z" fill="white"></path>
                        </svg>
                    </button>
                </div>
                <div className="alert alert-auth" style={{ display: this.state.showAlertAuth ? "block" : "none" }}>
                    Você precisa selecionar "Sim" ou "Não" para esta autorização antes de prosseguir.
                </div>
            </>);
        }
        else if (index === authPageNumber && this.props.indicadorSelfie && !this.state.isDoneSelfie) {
            return (<WebcamStreamCapture token={this.props.token} verifyMatch={this.props.indicadorMatch} conteudoLGPD={this.props.conteudoTextoLGPD} conteudoOrientacoes={this.props.conteudoTextoOrientacoes} onDone={this.handleWebcamCallback} onCancel={this.handleWebcamCancel} onInvalid={this.handleWebcamInvalid}></WebcamStreamCapture>);
        }
        else if (index === authPageNumber && (!this.props.indicadorSelfie || this.state.isDoneSelfie)) {
            const textosAutorizacaoObrigatoria = this.props.data && this.props.data.textosAutorizacoes && this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 3));
            const documentUrl = this.props.data ? this.props.data.conteudoURLContrato : "";
            const isAdesao = this.props.data && this.props.data.indicadorAdesao;
            const isMultiploContratos = this.props.data && this.props.data.indicadorMultiplosContratos;
            const termosTipo2 = this.props.data && this.props.data.textosAutorizacoes && this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 2));
            if (documentUrl != "") {
                return (<>
                    <div className="d-md-block">
                        {textosAutorizacaoObrigatoria && textosAutorizacaoObrigatoria.map((c, index) => (
                            <p key={index}>{c.conteudoTexto}</p>
                        ))}
                    </div>
                    {documentUrl && this.getButtonVerContrato(isMultiploContratos, documentUrl)}
                    <div className="text-aceite">
                        <input className="form-check-input-chk" type="checkbox" id="check-terms"
                            checked={this.state.checkedTerms} onChange={this.checkTerms} />
                        <span className="form-check-label-dark">
                            Declaro que li e estou de acordo com as condições {isMultiploContratos ? <> dos contratos</> : <>do <a href={documentUrl} target="_blank" rel="noopener noreferrer" onClick={() => { this.setState({ emprestimoDownloaded: true, showAlertDownload: false }) }}>contrato</a></>} {this.getTermosConsentimento(isAdesao)} acima e
                            com os <button className="anchor" onClick={() => { this.setState({ modalTermsOpen: true }) }}>Termos de Uso</button> e
                            a <button className="anchor" onClick={() => { this.setState({ modalPrivacyOpen: true }) }}>Política de Privacidade.</button>
                        </span>
                    </div>
                    <div className="alert alert-download" style={{ display: this.state.showAlertDownload ? "block" : "none" }}>
                        Clique no botão "Ver meu contrato" e leia antes de confirmar a contratação.
                    </div>
                    <div className="alert alert-terms" style={{ display: this.state.showAlertTerms ? "block" : "none" }}>
                        Você precisa aceitar os termos e condições para confirmar que está de acordo com o contrato.
                    </div>
                    {
                        termosTipo2 && termosTipo2.map((t, index) => (<div key={index}>
                            <div className="text-aceite">
                                <input className="form-check-input-chk" type="checkbox" id={"check" + index}
                                    checked={this.state.contracts && this.state.contracts[index] && this.state.contracts[index].checked ? true : false}
                                    onChange={() => { this.checkType2(index) }} />
                                <label className="form-check-label-dark" htmlFor={"check" + index}>
                                    {t.conteudoTexto}
                                </label>
                            </div>
                            <div className="alert alert-contract" style={{ display: this.state.contracts && this.state.contracts[index].showAlert ? "block" : "none" }}>
                                {t && t.conteudoTexto && t.conteudoTexto.includes("seiscentos")
                                    ? "Para prosseguir é necessário que declare que esta contratação não comprometa o orçamento para arcar com as suas despesas básicas e de sua família."
                                    : "Você precisa estar de acordo com esse contrato para confirmar."}
                            </div>
                        </div>))
                    }
                    <button type="button" className="btn btn-primary mt-3" onClick={this.checkConfirmation}>Confirmar</button>
                    <button type="button" className="btn btn-cancel" onClick={() => { this.checkDenyContract(undefined) }}>Desistir</button>
                </>);
            }
            else {
                return (<>
                    <div className="d-md-block">
                        {textosAutorizacaoObrigatoria && textosAutorizacaoObrigatoria.map((c, index) => (
                            <p key={index}>{c.conteudoTexto}</p>
                        ))}
                    </div>
                    {
                        termosTipo2 && termosTipo2.map((t, index) => (<div key={index}>
                            <div className="text-aceite">
                                <input className="form-check-input-chk" type="checkbox" id={"check" + index}
                                    checked={this.state.contracts && this.state.contracts[index] && this.state.contracts[index].checked ? true : false}
                                    onChange={() => { this.checkType2(index) }} />
                                <label className="form-check-label-dark" htmlFor={"check" + index}>
                                    {t.conteudoTexto}
                                </label>
                            </div>
                            <div className="alert alert-contract" style={{ display: this.state.contracts && this.state.contracts[index].showAlert ? "block" : "none" }}>
                                Você precisa estar de acordo com esse contrato para confirmar.
                            </div>
                        </div>))
                    }
                    <button type="button" className="btn btn-primary mt-3" onClick={this.checkConfirmation}>Confirmar</button>
                    <button type="button" className="btn btn-cancel" onClick={() => { this.checkDenyContract(undefined) }}>Desistir</button>
                </>);
            }
        }
    }
    private checkValue = (selected: boolean) => {
        this.setState({ checkedYes: selected, checkedNo: !selected });
        const index: number = this.state.currentAuth ? this.state.currentAuth : 0;
        const textoAutorizacao = this.props.data && this.props.data.textosAutorizacoes && this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 1))[index];
        if (textoAutorizacao) {
            textoAutorizacao.valor = selected;
        }
    }
    private checkValueSelected = () => {
        this.setState({ showAlertAuth: false });
        const index: number = this.state.currentAuth ? this.state.currentAuth : 0;
        const textoAutorizacao = this.props.data && this.props.data.textosAutorizacoes && this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 1))[index];
        if (textoAutorizacao) {
            if (textoAutorizacao.valor !== undefined && textoAutorizacao.valor) {
                this.jumpNextStep();
            }
            else if (textoAutorizacao.valor !== undefined && !textoAutorizacao.valor) {
                this.jumpNextStep();
            }
            else {
                this.setState({ showAlertAuth: true });
            }
        }
    }
    private checkTerms = () => {
        if (this.checkContractDowloaded()) {
            this.setState({ checkedTerms: !this.state.checkedTerms, showAlertTerms: this.state.checkedTerms, showAlertDownload: false });
        } else {
            this.setState({ showAlertDownload: true });
        }
    }

    private openContract = (isEmprestimo: boolean) => {
        if(isEmprestimo)
            this.setState({ emprestimoDownloaded: true });
        else 
            this.setState({ cartaoDownloaded: true });

        if(this.state.cartaoDownloaded && this.state.emprestimoDownloaded)
            this.setState({showAlertDownload: false });
    }

    private checkContractDowloaded = () => {
        const isMultiploContratos = this.props.data && this.props.data.indicadorMultiplosContratos;
        if (!isMultiploContratos && (this.state.cartaoDownloaded || this.state.emprestimoDownloaded))
            return true;
        else if(isMultiploContratos && this.state.cartaoDownloaded && this.state.emprestimoDownloaded)
            return true;
        else 
            return false;
    }

    private checkType2 = (index: number) => {
        let contracts = this.state.contracts;
        let contract = this.state.contracts && this.state.contracts[index];
        if (contracts && contract) {
            contracts[index].checked = !contracts[index].checked;
            contracts[index].showAlert = !contracts[index].checked;
            this.setState({ contracts: contracts, yada: this.state.yada + 1 });
        }
    }
    private handleWebcamCallback = (val: string | undefined, idOnBase: number | undefined) => {
        this.props.setNavPendente(this.props.currentPage !== undefined ? this.props.currentPage : 0, this.props.currentAuth !== undefined ? this.props.currentAuth : 0, true);
        this.setState({ isDoneSelfie: true, idOnBase: idOnBase });
    }
    private handleWebcamCancel = (val: string | undefined) => {
        this.setState({ isDoneSelfie: false });
        this.checkDenyContract(undefined);
    }
    private handleWebcamInvalid = (val: string | undefined) => {
        this.setState({ showBackArrow: false });
    }

    //Autorização Obrigatória
    private checkConfirmation = () => {
        let contracts = this.state.contracts;
        const documentUrl = this.props.data ? this.props.data.conteudoURLContrato : "";
        let allChecked = true;
        if (contracts) {
            contracts.forEach((c, index) => {
                c.showAlert = false;
                if (!c.checked) {
                    allChecked = false;
                    c.showAlert = true;
                }
            })
        }
        this.setState({ showAlertTerms: false, contracts: contracts });
        if (allChecked && (this.state.checkedTerms || documentUrl == "")) {
            let textos = this.props.data && this.props.data.textosAutorizacoes;
            textos && textos.forEach((t, index) => {
                if (t.indicadorTipoTexto === 2 || t.indicadorTipoTexto === 3) {
                    t.valor = true;
                }
            })
            const payload: PostInputAutorizacaoConfirmada = {
                token: this.props.token,
                textosAutorizacoes: textos,
                identificadorDocumentoOnBase: this.state.idOnBase && this.state.idOnBase > 0 ? this.state.idOnBase : 0
            }
            this.props.requestAutorizacaoConfirmada(payload)
            this.props.history.push(`/contrato-confirmado/${this.props.token}`);
            if (this.unregisterHistoryListener) {
                this.unregisterHistoryListener();
            }
        } else {
            if (!this.state.checkedTerms) {
                this.setState({ showAlertTerms: true });
            }
        }
        this.setState({ yada: this.state.yada + 1 });
    }
    private checkDenyContract = (response: string | undefined) => {
        const token: string = this.props.token ? this.props.token : '';
        this.props.requestAutorizacaoNegada(token, 2, `/informacoes-contrato/${this.props.token}`);
        if (response) {
            this.props.history.push(`/falha-face-match/${this.props.token}`);
        } else {
            this.props.history.push(`/contrato-cancelado/${this.props.token}`);
        }
        if (this.unregisterHistoryListener) {
            this.unregisterHistoryListener();
        }
    }

    //Modals
    private modalTermos = () => {
        return (<>
            <Modal isOpen={this.state.modalTermsOpen} toggle={() => { this.setState({ modalTermsOpen: !this.state.modalTermsOpen }) }}>
                <ModalHeader toggle={() => { this.setState({ modalTermsOpen: !this.state.modalTermsOpen }) }}>Termos de Uso dos Sites</ModalHeader>
                <ModalBody>
                    <p>Os sites do Banco Mercantil têm o objetivo de transmitir a melhor e mais completa informação a seus clientes e usuários. A navegação pelos sites significa que você concorda expressamente com as normas desta página e da Política Institucional de Privacidade, em conformidade com a lei nº 13.709/18. Você não pode copiar ou distribuir, total ou parcialmente, o conteúdo desses sites, sem a prévia autorização, por escrito do Banco Mercantil.</p>
                    <p>Ao utilizar os serviços do Internet Banking, você declara ter lido e concordado com todas as regras constantes nos Termos de Uso. Caso queira recomendar alguma página destes sites, você pode criar um link para ela. O link deve estar numa janela de navegador "limpa", ou seja, sem frames, pop-ups, barras de navegação ou outros elementos que identifiquem o conteúdo, direta ou indiretamente, com outros sites. O Banco Mercantilnão garante a disponibilidade permanente dos sites e não se responsabiliza por possíveis prejuízos causados pela indisponibilidade temporária desses serviços.</p>
                    <p>O uso dos programas (softwares) para a navegação nos sites, como navegador (browser), programas acessórios (plug-ins) e outros, são regidos por normas próprias estabelecidas pelos fabricantes desses produtos. É de sua responsabilidade utilizar nosso plug-in de segurança ao acessar os nossos serviços, para que possamos efetuar as validações necessárias, que garantirão um acesso mais seguro a você. O Banco Mercantil não se responsabiliza por problemas ou danos que esses programas possam causar em qualquer computador, e se reserva do direito de modificar, a qualquer momento, as regras dispostas nessa página. Em caso de alteração significativa, será publicado um comunicado na página principal dos sites.</p>
                    <p><strong>Termos de Uso de Aplicativos Móveis</strong></p>
                    <p>Ao usar nossos aplicativos você aceita e concorda com as normas expressas nessa página e na Política Institucional de Privacidade. Portanto, antes de usar, leia com atenção essas cláusulas e, se não concordar, por favor, entre em contato com nossos canais de atendimento para melhor entendê-los. Somente com seu aceite ficaremos habilitados a prestar nossos serviços.</p>
                    <p><strong>1. Objetivo</strong></p>
                    <p>Esse Termo é um acordo entre o Banco Mercantil e você, cliente. Nele estão escritas as condições de uso e obrigações de cada parte. Então, ao fazer seu login em nossos aplicativos, você concorda com o mesmo. Para que possamos continuar lhe atendendo com transparência, consulte regularmente esses Termos de Uso pois, com a atualização, lançamento ou descontinuidade de serviços, funcionalidades, ou por questões legais, podemos atualizá-los. Nossos aplicativos têm serviços e funções que também podem ser oferecidos por nossos parceiros, portanto, é importante que você conheça os termos e as condições de cada um deles.</p>
                    <p><strong>2. Sobre o uso</strong></p>
                    <p>Para acessar as informações restritas sobre sua conta e contratar nossos produtos e serviços é preciso fazer seu login. Todos os recursos tecnológicos, marcas, materiais e sistemas usados são de nossa propriedade autoral e sua alteração ou reprodução é proibida. Não nos responsabilizamos por eventuais prejuízos causados por uso indevido dos nossos aplicativos.</p>
                    <p><strong>É proibido:</strong></p>
                    <ul>
                        <li>Compartilhar suas senhas de acesso com outras pessoas;</li>
                        <li>Utilizar nossos aplicativos de forma que viole a legislação vigente;</li>
                        <li>Utilizar malwares e ferramentas de engenharia reversa, ou qualquer ação massificada, sem nossa autorização.</li>
                    </ul>
                    <p><strong>É sua responsabilidade:</strong></p>
                    <ul>
                        <li>Manter sua senha em sigilo: nunca conte sua senha para outra pessoa;</li>
                        <li>Cuidar do seu cartão e guardá-lo em local seguro. Em caso de perda ou roubo, fale conosco.</li>
                        <li>Fazer as atualizações gratuitas dos aplicativos: transações feitas em meio eletrônico podem ser alvo de ataques, por isso é muito importante que você mantenha seu dispositivo de acesso atualizado e com softwares de segurança.</li>
                        <li>Usar nosso plugin de segurança ao acessar os nossos serviços, para que possamos efetuar as validações necessárias, que garantirão um acesso mais seguro a você. Ao se cadastrar ou abrir uma conta em nossos aplicativos, você passa a ser nosso cliente.</li>
                    </ul>
                    <p><strong>3. Sobre o tratamento dos dados</strong></p>
                    <p>Ao se cadastrar para acessar nossos aplicativos, você está ciente e nos autoriza a fazer o tratamento dos seus dados pessoais, sensíveis ou não, em território nacional, para concluirmos a sua solicitação. Se você contratar algum serviço ou produto de nossos parceiros, você também dá ciência e os autoriza a fazer os tratamentos dos seus dados pessoais, sensíveis ou não, em território nacional, para conclusão do seu pedido. Para mantermos a transparência dos nossos serviços é importante você entender que a sua autorização para esse tratamento de dados significa a: classificação, arquivamento, processamento, armazenamento, transferência, exclusão, coleta, acesso ou recepção de seus dados.</p>
                    <p><strong>4. Sobre a criação da conta</strong></p>
                    <p>Para abrir sua conta você deve enviar imagens de documentos para que possamos validar seus dados, como por exemplo Carteira de Identidade ou CNH e comprovante de residência. Você deverá fotografar esses documentos pela câmera do seu smartphone ou tablet que tiver o aplicativo instalado, e permitir a transmissão do arquivo. Se você optar por não enviar esses dados e documentos, sua conta não será aberta e, com isso, o acesso à nossa área não será permitido.</p>
                    <p><strong>5. Sobre a disponibilidade</strong></p>
                    <p>Nosso objetivo é entregar um serviço de qualidade. Eventualmente podem ocorrer falhas técnicas, de infraestrutura ou telecomunicações, interrupções para manutenções programadas ou emergenciais e anomalias cibernéticas, que poderão indisponibilizar o seu acesso aos nossos aplicativos. Se tivermos algum problema não vamos medir esforços para normalizar a situação o mais rápido possível.</p>
                    <p><strong>6. Sobre a suspensão de acesso</strong></p>
                    <p>Se for identificado o uso indevido dos nossos aplicativos, o seu acesso poderá ser interrompido.</p>
                    <p><strong>7. Sobre a legislação aplicável</strong></p>
                    <p>A legislação brasileira.</p>
                    <p><strong>Alô, Mercantil: 0800 70 70 398 SAC<br />WhatsApp: 0800 70 70 398<br />Ouvidoria: 0800 70 70 384</strong></p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => { this.setState({ modalTermsOpen: !this.state.modalTermsOpen }) }}>Fechar</Button>
                </ModalFooter>
            </Modal>
        </>);
    }
    private modalPrivacy = () => {
        return (<>
            <Modal isOpen={this.state.modalPrivacyOpen} toggle={() => { this.setState({ modalPrivacyOpen: !this.state.modalPrivacyOpen }) }}>
                <ModalHeader toggle={() => { this.setState({ modalPrivacyOpen: !this.state.modalPrivacyOpen }) }}>Política Institucional de Privacidade</ModalHeader>
                <ModalBody>
                    <p><strong>1.	INTRODUÇÃO</strong></p>
                    <p>A Lei nº 13.709/18, também conhecida como Lei Geral de Proteção de Dados Pessoais (LGPD), é o primeiro marco regulatório abrangente brasileiro.  Até então, muito embora os direitos à intimidade e à vida privada fossem assegurados pela Constituição Federal Brasileira (1988) e pelo Código Civil (2002), há também normas setoriais que contemplam assuntos relacionados a dados pessoais como, por exemplo, o Código de Defesa do Consumidor, o Marco Civil da Internet, Lei Complementar nº 105 e a Lei do Cadastro Positivo.</p>
                    <p>É importante destacar que a LGPD estabelece como fundamentos da proteção de dados, além do respeito à privacidade, a inviolabilidade da intimidade, a liberdade de expressão, de informação, de comunicação e de opinião, assim como o desenvolvimento econômico e tecnológico, a inovação, a livre concorrência e a livre iniciativa. </p>
                    <p>Como parte da Governança Corporativa desenvolvida para adequar as empresas do Conglomerado Banco Mercantil às obrigações previstas na LGPD, este documento formaliza as diretrizes e as estruturas pertinentes ao atendimento a Lei nº 13.709/18, as quais foram desenhadas em compatibilidade com a natureza, o porte, a complexidade, a estrutura, o perfil de risco e o modelo de negócio da Instituição, bem como sob a ótica do relacionamento com o cliente, da modernização e segurança do sistema financeiro.</p>
                    <p><strong>2.	ABRANGÊNCIA </strong></p>
                    <p>Esta política abrange todos os administradores, colaboradores das empresas do Conglomerado Banco Mercantil, assim como terceiros, prestadores de serviço e/ou fornecedores, que, em função do desempenho de suas atividades, utilizem de dados pessoais dos titulares, inclusive nos meios digitais, por pessoa natural ou jurídica, no território nacional ou em países onde os mesmos estejam localizados.</p>
                    <p><strong>3.	ESTRUTURA DE GESTÃO LGPD </strong></p>
                    <p>A gestão da LGPD no Banco Mercantil é constituída por um <strong>Programa de Privacidade</strong>, cujas diretrizes de atuação são definidas por meio da presente Política, sendo abrangente e sólido ao contemplar a estrutura de controles internos definida pelo Banco Mercantil, sua governança, ferramentas e processos de monitoramento dos dados, fluxo de atendimento aos direitos dos titulares, relacionamento com órgãos regulamentares, segurança da informação e de acesso, comunicação interna e externa, treinamento e normatização.</p>
                    <p>A governança foi definida de forma a centralizar a gestão da exposição ao risco de LGPD e o relacionamento com a Autoridade Nacional de Proteção de Dados (ANPD) na figura do DPO – Data Protection Officer, também nomeado como “encarregado” pela lei.</p>
                    <p><strong>4.	CONCEITOS</strong></p>
                    <p>A origem dos dados pode ser diversa, cada qual sendo tratado dentro da finalidade para a qual foi capturado, fonte e respectiva base legal, podendo citar:</p>
                    <ul>
                        <li><strong>Dados informados pelo cliente</strong>: aqueles que a própria pessoa comunica, com perfil cadastral;</li>
                        <li><strong>Dados coletados de terceiros</strong>: informações relativas à situação financeira de um cliente, em geral com a finalidade de concessão de crédito, prevenção a fraudes e garantia de segurança aos clientes;</li>
                        <li><strong>Dados de navegação</strong>: informações coletadas quando um usuário navega em nossos ambientes/serviços (como site, app ou API), incluindo dados do dispositivo, IPs, “cookies” e geolocalização; </li>
                        <li><strong>Dados originados pelo histórico de relacionamento do cliente com a Instituição,</strong> caracterizando comportamento de uso de produtos e serviços, transações, canais de atendimento, entre outros;</li>
                        <li><strong>Dados públicos</strong>: coletados ou adquiridos de fornecedores especializados, mediante cuidados quanto à sua origem, são informações disponíveis publicamente ou que foram tornadas públicas pelo cliente.</li>
                    </ul>
                    <p><strong>5.	PRINCÍPIOS</strong></p>
                    <p>O Banco Mercantil utiliza de princípios que estabelecem as diretrizes e limitações sobre como os dados pessoais são tratados pela Instituição, quando da realização de operações com a sua utilização. São eles:</p>
                    <ul>
                        <li>Finalidade;</li>
                        <li>Adequação;</li>
                        <li>Necessidade;</li>
                        <li>Livre Acesso;</li>
                        <li>Qualidade dos dados;</li>
                        <li>Transparência;</li>
                        <li>Segurança;</li>
                        <li>Prevenção;</li>
                        <li>Não discriminação;</li>
                        <li>Responsabilização e Prestação de Contas.</li>
                    </ul>
                    <p><strong>6.	BASE LEGAL</strong></p>
                    <p>A LGPD estabelece dez hipóteses para o tratamento de dados pessoais, as quais constituem sua base legal, devendo ser observada a cada tratamento. Tais hipóteses representam uma evolução em relação ao Marco Civil da Internet, o qual previa apenas a obtenção do consentimento do titular dos dados para seu tratamento. Vide abaixo as hipóteses que constituem a base legal do Banco Mercantil, quando aplicáveis:</p>
                    <ul>
                        <li>Cumprimento de obrigação legal ou regulatória pelo Controlador;</li>
                        <li>Proteção do crédito;</li>
                        <li>Execução de contrato ou de procedimentos preliminares relacionados a contrato, do qual seja parte o titular, a pedido do titular dos dados;</li>
                        <li>Exercício regular de direitos em processo judicial, administrativo ou arbitral;</li>
                        <li>Proteção à vida ou da incolumidade física do titular ou do terceiro;</li>
                        <li>Interesse legítimo do Controlador ou do terceiro;</li>
                        <li>Consentimento;</li>
                        <li>Tratamento e uso compartilhado de dados necessários à execução de políticas públicas previstas em leis e regulamentos ou respaldadas em contratos, convênios ou instrumentos congêneres – este aplicável à administração pública;</li>
                        <li>Realização de estudos por órgãos de pesquisa; </li>
                        <li>Tutela da saúde.</li>
                    </ul>
                    <p>É importante destacar que não há base legal que prevaleça em relação a outra. Assim, ao definir a justificativa aplicável a uma operação de tratamento, deve-se buscar a base legal mais pertinente, considerando a finalidade para qual os dados serão utilizados, a abrangência, e os requisitos específicos. Cabe destacar que é possível que haja mais de uma base legal que justifique o tratamento na execução da operação. </p>
                    <p><strong>7.	FERRAMENTAS DE GESTÃO DE PRIVACIDADE - LGPD</strong></p>
                    <p>Equilibrar e integrar as necessidades de privacidade e segurança aos objetivos estratégicos da Instituição é um desafio que está presente no dia a dia dos gestores do Banco Mercantil, visando garantir, além da conformidade, os requisitos legais e mitigação de riscos no desenvolvimento de produtos, processos e sistemas robustos.</p>
                    <p>As ferramentas utilizadas na Gestão de Privacidade - LGPD podem ser categorizadas em Controle de Dados, Direito dos Titulares, Segurança e Governança, as quais estão em constante evolução para refletir os objetivos de negócio da Instituição, seus ativos e novos cenários de vulnerabilidades.</p>
                    <p><strong>7.1.	Gestão de dados</strong></p>
                    <p>É uma das mais importantes e abrangentes ferramentas do programa, pois contempla os controles que a Instituição possui sobre os dados dos processos de negócios e de TI, permitindo uma visão geral dos riscos a que a Instituição está exposta em relação à LGPD considerando o conhecimento do tratamento utilizado e a identificação da finalidade atribuída para o uso do dado. É composta pelas seguintes etapas:</p>
                    <ul>
                        <li>Mapeamento de dados;</li>
                        <li>Inventário de dados (automático ou manual); </li>
                        <li>Análise de Gap’s e criação do roadmap estratégico.</li>
                    </ul>
                    <p><strong>7.2.	Direitos do Titular</strong></p>
                    <p>Além das informações claras, o titular tem o direito de obter, gratuitamente, mediante solicitação expressa ao Controlador, as seguintes providências:</p>
                    <ul>
                        <li>Confirmação da existência de tratamento e acesso aos dados pessoais;</li>
                        <li>Acesso aos dados;</li>
                        <li>Correção dos dados incompletos, inexatos e desatualizados;</li>
                        <li>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a legislação;</li>
                        <li>Portabilidade dos dados a outro fornecedor de serviço ou produto;</li>
                        <li>Informação a respeito do uso compartilhado de dados pessoais;</li>
                        <li>Possibilidade de revogação do consentimento, por procedimento gratuito e facilitado;</li>
                        <li>Informações sobre a possibilidade de não fornecimento do consentimento;</li>
                        <li>Direito de oposição de processamento;</li>
                        <li>Direitos relacionados à revisão de decisão automatizada.</li>
                    </ul>
                    <p><strong>7.3.	Segurança</strong></p>
                    <p>Para garantir a segurança das informações fornecidas pelos clientes, o Banco Mercantil dispõe de processos de segurança físicos, lógicos, técnicos e administrativos compatíveis com a sensibilidade das informações coletadas, cuja eficiência é periodicamente avaliada.</p>
                    <p>No tratamento das informações coletadas, utiliza de sistemas estruturados de forma a atender aos requisitos de segurança e transparência, aos padrões de boas práticas e de governança, conforme disposto na Política Corporativa de Segurança da Informação e na Política Institucional de Segurança Cibernética. Ambos os documentos estão formalizados em base normativa e divulgados a todos colaboradores, parceiros e terceiros. </p>
                    <p>Ressalta-se que os recursos e as instalações de processamento de informações críticas do negócio são mantidos em áreas seguras e padronizadas, a fim de evitar acesso não autorizado, dano e/ou interferência nas informações e instalações da Instituição. As atividades do negócio, os processos críticos e os equipamentos são protegidos contra interrupções das atividades, sejam elas decorrentes de falhas, crises ou desastres. </p>
                    <p>Considerando o elevado volume de dados pessoais mantidos pela Instituição e o nível de controle e governança aplicado a esses dados, o Banco Mercantil possui processos de manutenção e melhoria contínua, dispostos em políticas e normas internas.</p>
                    <p><strong>7.4.	Governança</strong></p>
                    <p>O modelo de governança desenvolvido no Banco Mercantil para o Programa de Privacidade é estruturado da seguinte forma:</p>
                    <ul>
                        <li>DPO – Data Protection Officer (encarregado);</li>
                        <li>Normatização;</li>
                        <li>Gestão de fornecedores, terceiros, parceiros e contratos;</li>
                        <li>Gestão de riscos;</li>
                        <li>Resposta a incidentes;</li>
                        <li>Gestão de Expurgo.</li>
                    </ul>
                    <p><strong>8.	TREINAMENTO/COMUNICAÇÃO</strong></p>
                    <p>Os treinamentos são reciclados periodicamente visando mantê-los aderentes à legislação, sendo elaborados com conteúdo robusto que possibilite o entendimento dos aspectos da lei aplicados ao dia a dia da Instituição.</p>
                    <p>Além dos treinamentos, são realizadas comunicações por meio dos diversos canais internos, objetivando atingir todos os colaboradores do Banco Mercantil.</p>
                    <p><strong>9.	PAPÉIS E RESPONSABILIDADES</strong></p>
                    <p>Os papéis e as responsabilidades da estrutura de gestão da LGPD no Banco Mercantil estão distribuídos em diferentes comitês e níveis hierárquicos: </p>
                    <ul>
                        <li>Conselho de Administração;</li>
                        <li>Comitê Executivo; </li>
                        <li>Comitê Condutas Éticas;</li>
                        <li>Diretoria de Riscos e Compliance; </li>
                        <li>DPO; </li>
                        <li>Diretoria de Produtos, TI e Inovação; </li>
                        <li>Diretoria Jurídica e Relações com os Investidores; </li>
                        <li>Diretoria Executiva de Crédito, Gente e Marketing;  </li>
                        <li>Diretoria Executiva de Negócios e Operações; </li>
                        <li>Todos os Colaboradores;</li>
                        <li>Prestadores de Serviços, Terceiros e Parceiros.</li>
                    </ul>
                    <p><strong>10.	RELATÓRIOS E REPORTES</strong></p>
                    <p>O gerenciamento do Programa de Privacidade e do risco de LGPD é suportado e evidenciado por meio de relatórios de gestão, os quais geram visão consolidada da exposição ao risco de LGPD, além de suportar o acompanhamento dos incidentes e de planos de ação decorrentes de inconformidades e adequações identificadas, com o propósito de apoiar os processos internos na garantia da conformidade da Instituição à LGPD.</p>
                    <p>Além da gestão contínua, foi desenvolvido o Relatório de Impacto, em atendimento ao disposto no artigo n° 38 da Lei 13.709/2018. Este Relatório tem como objetivo fornecer o cenário da Instituição quanto aos processos que envolvem dados pessoais, apresentando a descrição dos tipos de dados tratados e a metodologia utilizada para coletá-los, das medidas que são adotadas para mitigar os riscos que possam afetar a segurança das informações, além dos subsídios para avaliação e tratamento dos riscos pelo controlador.</p>
                    <p><strong>11.	INSTRUMENTOS DE APOIO À GESTÃO DE PRIVACIDADE – LGPD</strong></p>
                    <ul>
                        <li>Código de Condutas Éticas;</li>
                        <li>Política de Gestão das Consequências;</li>
                        <li>Política Corporativa de Segurança da Informação; </li>
                        <li>Política Institucional de Segurança Cibernética;</li>
                        <li>Política de Cookies;</li>
                        <li>Política de Uso do Site;</li>
                        <li>Gestão de Incidentes.</li>
                    </ul>
                    <p><strong>12.	CONSIDERAÇÕES FINAIS</strong></p>
                    <p>Por meio desta Política, o Grupo Banco Mercantil reafirma seu compromisso com a transparência na proteção de dados e respeito à privacidade, fortalecendo seu ambiente de controles internos para assegurar a conformidade às exigências legais e de órgãos de supervisão, e proporcionar a sustentabilidade da Instituição.</p>
                    <p>Esta Política deve ser objeto de avaliação periódica, com o intuito de que seja continuamente aprimorada.</p>
                    <p>Este documento entra em vigor a partir de sua publicação, ficando à disposição dos órgãos de fiscalização e supervisão.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => { this.setState({ modalPrivacyOpen: !this.state.modalPrivacyOpen }) }}>Fechar</Button>
                </ModalFooter>
            </Modal>
        </>);
    }

    private modalConsentimento =()=>{
        return (<> 
            <Modal isOpen={this.state.modalTermoConsentimentoOpen} toggle={() => { this.setState({ modalTermoConsentimentoOpen: !this.state.modalTermoConsentimentoOpen }) }}>
                <ModalHeader toggle={() => { this.setState({ modalTermoConsentimentoOpen: !this.state.modalTermoConsentimentoOpen }) }}>Termo de Consentimento Esclarecido do Cartão de Crédito Consignado</ModalHeader>
                <ModalBody>
                    <p>Em cumprimento à sentença judicial proferida nos autos da Ação Civil Pública nº 106890-28.2015.4.01.3700, 3º Vara Federal da Seção Judiciária de São Luís/MA, proposta pela Defensoria Pública da União.</p>
                    <p>a) Contratei um Cartão de Crédito Consignado;</p>
                    <p>b) Fui informado que a realização de saque mediante a utilização do meu limite do Cartão de Crédito Consignado ensejará a indicidência de encargos e que o valor do saque, acrescido destes encargos, constará na minha próxima fatura do cartão;</p>
                    <p>c) A diferença entre o valor pago mediante consignação (desconto realizado diretamente na minha remuneração/benefício) e o total da fatura poderá ser paga por meio da minha fatura mensal, o que é recomendado pelo Banco Mercantil do Brasil, já que, caso a fatura não seja integralmente paga até a data de vencimento, incidirão encargos sobre o valor devido, conforme previsto na fatura;</p>
                    <p>d) Declaro ainda saber que existem outras modalidades de crédito, a exemplo do empréstimo consignado, que possuem juros mensais em percentuais menores;</p>
                    <p>e) Estou ciente de que a taxa de juros do cartão de crédito consignado é inferior à taxa de juros do cartão de crédito convencional;</p>
                    <p>f) Sendo utilizado o limite parcial ou total de meu cartão de crédito, para saques ou compras, em uma única transação, o saldo devedor do cartão será liquidado ao final de até 84 meses, contados a partir da data do primeiro desconto em folha, desde que:</p>
                    <ul>
                        <li>1. Eu não realize outras transações de qualquer natureza, durante todo o período de amortização projeto a partir da última utilização;</li>
                        <li>2. Não ocorra a redução/perda da minha margem consignável de cartão;</li>
                        <li>3. Os descontos através da consignação ocorram mensalmente, sem interrupção até o total da dívida;</li>
                        <li>4. Eu não realize qualquer pagamento espontâneo via fatura; e</li>
                        <li>5. Não haja alteração da taxa dos juros remuneratórios;</li>
                    </ul>
                    <p>g) Para tirar dúvidas acerca do contrato ora firmado, inclusive sobre informações presentes neste Termo de Consentimento, o cliente poderá entrar em contato gratuitamente com o Banco Mercantil do Brasil através do Serviço de Atendimento ao Consumidor <strong>Alô, Mercantil</strong> 0800 7070 398 SAC e de sua <strong>Ouvidoria</strong> 0800 7070 384.</p>
                    <p><strong>Este termo é parte integrante da Autorização para Reserva da Margem Consignável e Adesão ao Cartão de Crédito Consignado</strong></p>
                </ModalBody>           
            </Modal>
            </>
        );
    }

    //Back icon
    private renderBackArrow = () => {
        return (<>
            <div className="back-arrow" onClick={this.jumpPrevStep}>
                <svg width="28" height="24" viewBox="0 0 28 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.146 11L9.06153 11L15.5309 5.41L13.8875 4L4.62903 12L13.8875 20L15.5193 18.59L9.06153 13L23.146 13L23.146 11Z" fill="#ffffff"></path>
                </svg>
            </div>
        </>)
    };

    //Actions
    private jumpTo = (page: number) => {
        this.setState({ currentPage: page });
        this.props.setNavPendente(page, this.state.currentAuth !== undefined ? this.state.currentAuth : 0);
    }
    private jumpPrevStep = () => {
        if (this.state.currentPage === 1) {
            if (this.state.currentAuth === 0) {
                this.jumpTo(0);
            } else {
                const prevStep = this.state.currentAuth !== undefined ? this.state.currentAuth - 1 : 0;
                this.setState({ currentAuth: prevStep, checkedNo: undefined, checkedYes: undefined });
                this.props.setNavPendente(this.state.currentPage !== undefined ? this.state.currentPage : 0, prevStep);
            }
        } else if (this.state.currentPage !== undefined && this.state.currentPage > 1) {
            this.jumpTo(this.state.currentPage - 1);
        }
    }

    private navegateContract = (isNext: boolean) => {
        if (this.props.data && this.props.data.campos) {
            const camposOrdenados = this.props.data.campos.sort((a, b) => { return a.identificadorOperacao - b.identificadorOperacao });
            const ultimaOperacao = camposOrdenados.slice(-1)[0].identificadorOperacao;
            const primeiraOperacao = camposOrdenados[0].identificadorOperacao;
            this.validateNextStep(isNext, primeiraOperacao, ultimaOperacao);
        }
    }

    private validateNextStep = (isNext: boolean, primeiraOperacao: number, ultimaOperacao: number) => {
        const currentOperacao = this.state.currentOperacao || 1;

        if (isNext && (this.state.currentOperacao || 1) < ultimaOperacao) {
            this.setState({ currentOperacao: currentOperacao + 1 });
            window.scrollTo(0, 0);
        }
        else if (!isNext && (this.state.currentOperacao || 1) > primeiraOperacao) {
            this.setState({ currentOperacao: currentOperacao - 1 });
            window.scrollTo(0, 0);
        }
        else if (isNext)
            this.jumpNextStep();
    }

    private jumpNextStep = () => {
        const totalItems = this.props.data && this.props.data.textosAutorizacoes ? this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 1)).length : 0;
        if (this.state.currentPage === 1) {
            if (this.state.currentAuth === totalItems) {
                this.jumpTo(2);
            } else {
                if (this.state.currentAuth === (totalItems - 1)) {
                    this.setState({ isDoneSelfie: false });
                    this.mountContracts();
                }
                const nextStep = this.state.currentAuth !== undefined ? this.state.currentAuth + 1 : 0;
                this.setState({ currentAuth: nextStep, checkedNo: undefined, checkedYes: undefined });
                this.props.setNavPendente(this.state.currentPage !== undefined ? this.state.currentPage : 0, nextStep);
            }
        } else if (this.state.currentPage !== undefined && this.state.currentPage < 1) {
            if (this.state.currentAuth === 0 && totalItems === 0 && this.state.contracts === undefined) {
                this.mountContracts();
            }
            this.jumpTo(this.state.currentPage + 1);
        }
    }
    private mountContracts = () => {
        let contracts: contractsType2[] = [];
        this.props.data && this.props.data.textosAutorizacoes && this.props.data.textosAutorizacoes.filter((c) => (c.indicadorTipoTexto === 2)).forEach((c, index) => {
            contracts.push({
                id: index,
                checked: false,
                showAlert: false
            });
        });
        this.setState({ contracts: contracts, checkedTerms: false, showAlertTerms: false });
    }

    //Render
    public render() {
        return (
            <React.Fragment>
                {this.props.isLoading && <>
                    <Loading />
                </>}
                {!this.props.isLoading && <>
                    {this.props.data && this.props.data.indicadorErro !== undefined && this.props.data.indicadorErro !== 0 && <>
                        <div className="text-center card-title">
                            <h3 className="title">Oops...</h3>
                        </div>
                        <div className="mt-3" />
                        <div className="mb-3">
                            {this.props.data && this.props.data.descricaoErro}
                        </div>
                        <button type="button" className="btn btn-primary" value="reset-validation" onClick={() => {
                            this.props.history.push('/' + (this.props.token ? this.props.token : this.props.match.params.token));
                        }}>Voltar</button>
                    </>}
                    {this.props.data && this.props.data.indicadorErro === 0 && <>
                        {(!this.props.indicadorSelfie || this.state.isDoneSelfie) && <>
                            <div className="text-center card-title">
                                <h3 className="title">{this.props.data && this.props.data.descricaoProduto}</h3>
                            </div>
                        </>}
                        <div className="mt-3" />
                        {this.state && this.state.currentPage === 0 && <>
                            {this.renderSubtitle()}
                            {this.renderHighlighted()}
                            {this.renderRegular()}
                            <button type="button" className="btn btn-primary" value="submit-validation" onClick={() => { this.navegateContract(true); }}>Avançar</button>
                            {this.state.currentOperacao && this.state.currentOperacao > 1 && <button type="button" className="btn btn-cancel" onClick={() => { this.navegateContract(false) }}>Voltar</button>}
                            <button type="button" className="btn btn-cancel" onClick={() => { this.checkDenyContract(undefined) }}>Desistir</button>
                        </>}
                        {this.state && this.state.currentPage === 1 && <>
                            {this.renderAuthNav()}
                            {this.renderAuthorization()}
                            {this.state.showBackArrow && this.renderBackArrow()}
                        </>}
                        {this.state && this.state.currentPage === 2 && <>
                            <button type="button" className="btn btn-primary" value="reset-form" onClick={() => { this.jumpTo(0); }}>Voltar</button>
                        </>}
                    </>}
                </>}
                {this.modalTermos()}
                {this.modalPrivacy()}
                {this.modalConsentimento()}
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.autorizacaoPendente,
    AutorizacaoClienteStore.actionCreators
)(DadosContrato as any);
