import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as AutorizacaoClienteStore from '../../store/AutorizacaoCliente';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../Layout.css';

import Loading from '../Loading'
import { UnregisterCallback } from 'history';

type AutorizacaoNegadaProps =
    AutorizacaoClienteStore.AutorizacaoNegadaState
    & typeof AutorizacaoClienteStore.actionCreators
    & RouteComponentProps<{ token: string }>;

type DesistenciaState = {
    isValidated: boolean,
    numeroCPF?: string,
    codigoValidacao?: string,
}

class Desistencia extends React.PureComponent<AutorizacaoNegadaProps, DesistenciaState> {
    currentPathname: string;
    currentSearch: string;
    unregisterHistoryListener: UnregisterCallback | null;
    constructor(props: Readonly<AutorizacaoNegadaProps>) {
        super(props);
        this.state = { isValidated: false };
        this.currentPathname = '';
        this.currentSearch = '';
        this.unregisterHistoryListener = null;
    }

    public componentDidMount() {
        if (this.unregisterHistoryListener === null) {
            this.unregisterHistoryListener = this.props.history.listen((newLocation, action) => {
                if (action === "PUSH") {
                    if (newLocation.pathname !== this.currentPathname || newLocation.search !== this.currentSearch) {
                        this.currentPathname = newLocation.pathname;
                        this.currentSearch = newLocation.search;
                        this.props.history.push({
                            pathname: newLocation.pathname,
                            search: newLocation.search
                        });
                    }
                } else {
                    this.props.history.go(1);
                }
            });
        }
    }
    public componentWillUnmount() {
        if (this.unregisterHistoryListener) {
            this.unregisterHistoryListener();
        }
    }

    public componentDidUpdate() {
    }

    public undoDenyContract = () => {
        if (this.props.token) {
            const isValid = this.props.data && this.props.data.lastTime && this.props.data.lastTime.getTime() > (new Date().getTime() - 5 * 60 * 1000);
            const backUrl = isValid && this.props.data && this.props.data.lastPage ? this.props.data.lastPage : '/' + this.props.token;
            isValid && this.props.requestAutorizacaoNegada(this.props.token, 1, 'do');
            !isValid && this.props.requestAutorizacaoCliente(this.props.token, true);
            this.props.history.push(backUrl);
        }
    }

    public render = () => {
        return (<>
            {this.props.isLoading && <>
                <Loading />
            </>}
            {!this.props.isLoading && this.props.data && this.props.data.indicadorErro !== undefined && this.props.data.indicadorErro === 0 && <>
                <div className="text-center card-title">
                    <h3 className="title">
                        <FontAwesomeIcon icon={["far", "frown"]} /> Que pena!
                    </h3>
                </div>
                <div className="mt-3" />
                <div className="mb-3 text-justify">
                    <p>
                        {this.props.data && this.props.data.descricaoErro}
                    </p>
                    <p>
                        Se você tiver clicado sem querer no botão "Desistir" você pode continuar clicando no botão "Voltar".
                    </p>
                </div>
                <button type="button" className="btn btn-primary" value="submit-validation" onClick={() => { this.props.history.push(`/fechamento/${this.props.token}`); }}>Fechar</button>
                <button type="button" className="btn btn-cancel" value="submit-validation" onClick={this.undoDenyContract}>Voltar</button>
            </>}
            {!this.props.isLoading && this.props.data && this.props.data.indicadorErro !== undefined && this.props.data.indicadorErro !== 0 && <>
                <div className="text-center card-title">
                    <h3 className="title">Oops...</h3>
                </div>
                <div className="mt-3" />
                <div className="mb-3">
                    {this.props.data && this.props.data.descricaoErro}
                </div>
            </>}
        </>);
    }
}
export default connect(
    (state: ApplicationState) => state.autorizacaoNegada,
    AutorizacaoClienteStore.actionCreators
)(Desistencia as any);
