import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AutorizacaoClienteStore from '../../store/AutorizacaoCliente';
import '../Layout.css';

import Loading from '../Loading'
import { UnregisterCallback } from 'history';

type AutorizacaoConfirmadaProps =
    AutorizacaoClienteStore.AutorizacaoConfirmadaState
    & typeof AutorizacaoClienteStore.actionCreators
    & RouteComponentProps<{ token: string }>;

type ConfirmacaoState = {
    isValidated: boolean,
    numeroCPF?: string,
    codigoValidacao?: string,
}
type FileInfo = {
    title: string,
    filename: string,
    mimeType: string,
    contents: string
}
class Confirmacao extends React.PureComponent<AutorizacaoConfirmadaProps, ConfirmacaoState> {
    currentPathname: string;
    currentSearch: string;
    unregisterHistoryListener: UnregisterCallback | null;
    constructor(props: Readonly<AutorizacaoConfirmadaProps>) {
        super(props);
        this.state = { isValidated: false };
        this.currentPathname = '';
        this.currentSearch = '';
        this.unregisterHistoryListener = null;
    }

    public componentDidMount() {
        if (this.unregisterHistoryListener === null) {
            this.unregisterHistoryListener = this.props.history.listen((newLocation, action) => {
                if (action === "PUSH") {
                    if (newLocation.pathname !== this.currentPathname || newLocation.search !== this.currentSearch) {
                        this.currentPathname = newLocation.pathname;
                        this.currentSearch = newLocation.search;
                        this.props.history.push({
                            pathname: newLocation.pathname,
                            search: newLocation.search
                        });
                    }
                } else {
                    this.props.history.go(1);
                }
            });
        }
    }
    public componentWillUnmount() {
        if (this.unregisterHistoryListener) {
            this.unregisterHistoryListener();
        }
    }

    public componentDidUpdate() {
    }

    private downloadFile = (fileInfo: FileInfo) => {
        const element = document.createElement("a");
        const text = this.b64toBlob(fileInfo.contents, fileInfo.mimeType);
        const file = new Blob([text], { type: fileInfo.mimeType });
        element.href = URL.createObjectURL(file);
        element.target = "_blank";
        element.download = fileInfo.filename;
        document.body.appendChild(element);
        element.click();
        element.remove();
    }

    private b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    private resetToForm = () => {
        if (this.props.match.params.token) {
            this.props.setNavPendente(0, 0);
            this.props.requestAutorizacaoCliente(this.props.match.params.token, true);
            this.props.history.push(`/${this.props.match.params.token}`);
        }
    }

    public render = () => {
        let files: FileInfo[] = [];
        !this.props.isLoading && this.props.data && this.props.data.recibos && this.props.data.recibos.forEach((r, index) => {
            const fileInfo: FileInfo = {
                title: (r.nomeRecibo ? `${r.nomeRecibo}` : "Veja seu Recibo"),
                filename: (r.nomeRecibo ? r.nomeRecibo : "Recibo") + '.pdf',
                mimeType: 'application/pdf', //; charset=iso-8859-1
                contents: r.conteudoRecibo || ''
            };
            files.push(fileInfo);
        })
        return (<>
            {this.props.isLoading && <>
                <Loading />
            </>}
            {!this.props.isLoading && this.props.data && this.props.data.indicadorErro !== undefined && this.props.data.indicadorErro === 0 && <>
                <div className="text-center card-title">
                    <h3 className="title">
                        <FontAwesomeIcon icon={["far", "check-circle"]} /> {this.props.data && this.props.data.descricaoMensagemCabecalho}
                    </h3>
                </div>
                <div className="mt-3" />
                <div className="mb-3 text-justify">
                    <p>
                        {this.props.data && this.props.data.descricaoMensagemSucesso}
                    </p>
                </div>
                <div className="mb-3 text-justify sucesso-dado-complementar">
                    <p>
                        {this.props.data && this.props.data.descricaoMensagemSucessoComplemento}
                    </p>
                </div>
                {files && files.map((r, index) => (
                    <button type="button" key={index} className="btn btn-primary" onClick={() => { this.downloadFile(r) }}>
                        {r && r.title}
                    </button>
                ))}
            </>}
            {!this.props.isLoading && this.props.data && this.props.data.indicadorErro !== undefined && this.props.data.indicadorErro !== 0 && <>
                <div className="text-center card-title">
                    <h3 className="title">
                        {this.props.data && this.props.data.descricaoCabecalho}
                    </h3>
                </div>
                <div className="mt-3" />
                <div className="mb-3">
                    {this.props.data && this.props.data.descricaoErro}
                </div>
                {this.props.data.indicadorErro === 99 && <>
                    <div className="mb-3">
                        <button type="button" className="btn btn-primary" onClick={this.resetToForm}>Tentar novamente</button>
                    </div>
                </>}
            </>}
        </>);
    }
}
export default connect(
    (state: ApplicationState) => state.autorizacaoConfirmada,
    AutorizacaoClienteStore.actionCreators
)(Confirmacao as any);
