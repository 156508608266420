// import React, { useState, useEffect } from "react";

const MARGEM_TOLERANCIA: number = 0.1;
const NUM_ACOES_ADICIONAIS: number = 3;

export interface FaceAction {
    actionId: number;
    name: string;
    msg: string;
};

export function getFaceActions(): FaceAction[] {
    let result: FaceAction[] = [];

    const centerAction: FaceAction = {
        actionId: 0,
        name: 'centralizar',
        msg: 'Para começarmos, por favor CENTRALIZE o seu rosto DENTRO da área marcada, AFASTANDO-O ou APROXIMANDO-O DA TELA até que seja detectado!'
    };
    const leftAction: FaceAction = {
        actionId: 1,
        name: 'virar para a esquerda',
        msg: 'GIRE sua cabeça para a ESQUERDA em direção ao ombro.'
    };
    const rightAction: FaceAction = {
        actionId: 2,
        name: 'virar para a direita',
        msg: 'GIRE sua cabeça para a DIREITA em direção ao ombro.'
    };
    const upAction: FaceAction = {
        actionId: 3,
        name: 'virar para cima',
        msg: 'INCLINE sua cabeça levemente para CIMA'
    };
    const downAction: FaceAction = {
        actionId: 4,
        name: 'virar para baixo',
        msg: 'INCLINE sua cabeça levemente para BAIXO'
    };

    let sortableActions: FaceAction[] = [
        leftAction,
        rightAction,
        upAction,
        downAction
    ];

    result.push(centerAction);
    for (let x: number = 0; x < NUM_ACOES_ADICIONAIS; x++) {
        const num = Math.floor(Math.random() * sortableActions.length);
        const drawn: FaceAction = sortableActions.splice(num, 1)[0];
        result.push(drawn);
    }

    return result;
};

export function isCentered(landmarks: any, viewportWidth: number, viewportHeight: number): boolean {
    let result = false;

    const extremidadeFaceEsquerda = landmarks[454];
    const refExtremidadeFaceEsquerda = landmarks[447];
    const extremidadeFaceDireita = landmarks[234];
    const refExtremidadeFaceDireita = landmarks[227];
    const centro = landmarks[4];

    // const leftEyeCorner = landmarks[263];
    // const refLeftEye = landmarks[356];
    // const rightEyeCorner = landmarks[33];
    // const refRightEye = landmarks[127];

    // console.log(`difL: ${refLeftEye[0] - leftEyeCorner[0]}, difR: ${refRightEye[0] - rightEyeCorner[0]}`);

    const alignYAxis =
        parseFloat(extremidadeFaceEsquerda[0]) - parseFloat(refExtremidadeFaceEsquerda[0]) < 0 &&
        parseFloat(extremidadeFaceDireita[0]) - parseFloat(refExtremidadeFaceDireita[0]) > 0;
    // console.log(`Left: ${extremidadeFaceEsquerda[0] - refExtremidadeFaceEsquerda[0]} / Right: ${refExtremidadeFaceDireita[0] - extremidadeFaceDireita[0]} / OK: ${alignYAxis}`);

    const alignZAxis = 
        parseFloat(extremidadeFaceEsquerda[1]) - parseFloat(extremidadeFaceDireita[1]) > 0 - (viewportHeight * MARGEM_TOLERANCIA / 2) &&
        parseFloat(extremidadeFaceEsquerda[1]) - parseFloat(extremidadeFaceDireita[1]) < 0 + (viewportHeight * MARGEM_TOLERANCIA / 2);
    // console.log(`Left: ${extremidadeFaceEsquerda[1]} / Right: ${extremidadeFaceDireita[1]} / OK: ${alignZAxis}`);

    const horizontal: boolean =
        (parseFloat(centro[0]) >= ((viewportWidth / 2) - (viewportWidth * MARGEM_TOLERANCIA))) &&
        (parseFloat(centro[0]) <= ((viewportWidth / 2) + (viewportWidth * MARGEM_TOLERANCIA)));
    // console.log(`c: ${parseFloat(centro[0])}, m: ${viewportWidth / 2}, t: ${viewportWidth * MARGEM_TOLERANCIA} / OK: ${horizontal}`)

    const vertical =
        (parseFloat(centro[1]) >= (viewportHeight / 1.8 - (viewportHeight * MARGEM_TOLERANCIA))) &&
        (parseFloat(centro[1]) <= (viewportHeight / 1.8 + (viewportHeight * MARGEM_TOLERANCIA)));
    // console.log(`c: ${parseFloat(centro[1])}, m: ${viewportHeight / 1.8}, t: ${viewportHeight * MARGEM_TOLERANCIA} / OK: ${vertical}`)

    const posicionarCentralmente = horizontal && vertical;

    result = alignYAxis && alignZAxis && posicionarCentralmente; //alignYAxis && 
    // console.log(`${alinharHorizontalmente} : ${posicionarCentralmente}`);
    return result;
};

export function isLeftFaced(landmarks: any, viewportWidth: number, viewportHeight: number): boolean {
    let result = false;

    const extremidadeFaceEsquerda = landmarks[454];
    const extremidadeInternaOlhoEsquerdo = landmarks[362];
    const girarEsquerda = extremidadeFaceEsquerda[0] - extremidadeInternaOlhoEsquerdo[0] < 0;

    result = girarEsquerda;
    return result;
};

export function isRightFaced(landmarks: any, viewportWidth: number, viewportHeight: number): boolean {
    let result = false;

    const extremidadeFaceDireita = landmarks[234];
    const extremidadeInternaOlhoDireito = landmarks[133];
    const girarDireita = extremidadeInternaOlhoDireito[0] - extremidadeFaceDireita[0] < 0;

    result = girarDireita;
    return result;
};

export function isUpFaced(landmarks: any, viewportWidth: number, viewportHeight: number): boolean {
    let result = false;

    const extremidadeExternaOlhoDireito = landmarks[33];
    const refInclinarCima = landmarks[162];
    const inclinarCima = extremidadeExternaOlhoDireito[1] - refInclinarCima[1] < 0;

    result = inclinarCima;
    return result;
};

export function isDownFaced(landmarks: any, viewportWidth: number, viewportHeight: number): boolean {
    let result = false;

    // const extremidadeExternaOlhoDireito = landmarks[33]
    const inclinarBaixo = landmarks[94][1] - landmarks[19][1] < 0;

    result = inclinarBaixo;
    return result;
};