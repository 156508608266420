import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';

import './custom.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'

import Identificacao from './components/Identificacao';
import LinkInvalido from './components/LinkInvalido';
import DadosContrato from './components/DadosContrato';
import Confirmacao from './components/Confirmacao';
import Desistencia from './components/Desistencia';
import FaceMatchInvalido from './components/Desistencia/faceMatchInvalido';
import Fechamento from './components/Fechamento';

library.add(far)

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/:token?' component={Identificacao} />
        <Route path='/link-invalido/:token?' component={LinkInvalido} />
        <Route path='/informacoes-contrato/:token?' component={DadosContrato} />
        <Route path='/contrato-confirmado/:token?' component={Confirmacao} />
        <Route path='/contrato-cancelado/:token?' component={Desistencia} />
        <Route path='/falha-face-match/:token?' component={FaceMatchInvalido} />
        <Route path='/fechamento/:token?' component={Fechamento} />
    </Layout>
);
