import { Action, Reducer } from 'redux';
import { AppThunkAction } from './index';
import { GetToken, GetAutorizacaoPendente, PostOutputAutorizacaoConfirmada, PostInputAutorizacaoConfirmada, DeleteAutorizacaoNegada, GetSelfie, PostInputAcao, PostOutputAcao, PostInputMatch, PostOutputMatch, PostOutputArmazenarFoto, PostInputArmazenarFoto } from './autorizacaoClienteTypes';
import {
    RequestAutorizacaoClienteAction, ReceiveAutorizacaoClienteAction,
    RequestAutorizacaoPendenteAction, ReceiveAutorizacaoPendenteAction,
    RequestAutorizacaoConfirmadaAction, ReceiveAutorizacaoConfirmadaAction,
    RequestAutorizacaoNegadaAction, ReceiveAutorizacaoNegadaAction,
    RequestSelfieAction, ReceiveSelfieAction,
    SetNavPendenteAction,
    SetSelfieAction,
} from './autorizacaoClienteActions';
import JwtUtil, { JwtTokenData } from '../store/jwtUtil';
import axios from 'axios';

export interface AutorizacaoClienteState {
    isLoading: boolean;
    token?: string;
    data?: GetToken;
}

export interface AutorizacaoPendenteState {
    isLoading: boolean;
    token?: string;
    numeroCPF?: string;
    codigoValidacao?: string;
    currentPage?: number,
    currentAuth?: number,
    isDoneSelfie?: boolean,
    indicadorSelfie?: boolean,
    indicadorMatch?: boolean,
    conteudoTextoLGPD?: string,
    conteudoTextoOrientacoes?: string,
    data?: GetAutorizacaoPendente;
    currentOperacao?: number;
}

export interface AutorizacaoConfirmadaState {
    isLoading: boolean;
    input?: PostInputAutorizacaoConfirmada;
    data?: PostOutputAutorizacaoConfirmada;
}

export interface AutorizacaoNegadaState {
    isLoading: boolean;
    token?: string;
    indicadorStatus?: number;
    data?: DeleteAutorizacaoNegada;
}

export interface SelfieState {
    isLoading: boolean;
    token?: string;
    data?: GetSelfie;
}

//JwtToken
let savedToken: JwtTokenData = {};
const jwtToken = async () => {
    if (!savedToken || !savedToken.token || (savedToken.expiry && savedToken.expiry < new Date())) {
        savedToken = await JwtUtil();
    }
    return savedToken.token || "";
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    RequestAutorizacaoClienteAction | ReceiveAutorizacaoClienteAction | SetNavPendenteAction | SetSelfieAction |
    RequestAutorizacaoPendenteAction | ReceiveAutorizacaoPendenteAction |
    RequestAutorizacaoConfirmadaAction | ReceiveAutorizacaoConfirmadaAction |
    RequestAutorizacaoNegadaAction | ReceiveAutorizacaoNegadaAction |
    RequestSelfieAction | ReceiveSelfieAction;

const apiBase: string = process.env.REACT_APP_AUTORIZACAO_CLIENTE_URL || "";

let ip: string = "127.0.0.1";
const ipData = async () => {
    if (ip === "127.0.0.1") {
        try {
            const response = await axios.get('https://api.ipify.org/?format=json');
            ip = response.data.ip;
        } catch { }
    }
}
let userAgent: string = navigator.userAgent.replace(/[/;]/gi, '_');

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    requestAutorizacaoCliente: (token: string, reset?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        ipData()
            .then(() => {
                if (appState && appState.autorizacaoCliente && token && (token !== appState.autorizacaoCliente.token || reset)) {
                    jwtToken()
                        .then(jwtToken => {
                            const getOptions: RequestInit = {
                                method: 'GET',
                                headers: {
                                    'Authorization': jwtToken
                                }
                            };
                            fetch(apiBase + `Token/` + token + `/` + ip + `/` + userAgent, getOptions)
                                .then(response => {
                                    return response.json() as Promise<GetToken>;
                                })
                                .then(data => {
                                    dispatch({ type: 'RECEIVE_TOKEN_VALIDATION', token: token, data: data });
                                })
                                .catch(error => {
                                    const errorData: GetToken = {
                                        indicadorErro: 1,
                                        descricaoCabecalho: "Oops...",
                                        descricaoErro: `Tivemos um probleminha por aqui. Por favor, espere alguns minutos e tente novamente. Se não funcionar, acesse de novo o link que te enviamos no SMS.`
                                    }
                                    dispatch({ type: 'RECEIVE_TOKEN_VALIDATION', token: token, data: errorData });
                                });
                        })
                        .catch(error => {
                            const errorData: GetToken = {
                                indicadorErro: 1,
                                descricaoCabecalho: "Oops...",
                                descricaoErro: `Tivemos um probleminha por aqui. Por favor, espere alguns minutos e tente novamente. Se não funcionar, acesse de novo o link que te enviamos no SMS.`
                            }
                            dispatch({ type: 'RECEIVE_TOKEN_VALIDATION', token: token, data: errorData });
                        });
                    dispatch({ type: 'REQUEST_TOKEN_VALIDATION', token: token });
                }
            });
    },
    requestAutorizacaoPendente: (token: string, numeroCPF: string, codigoValidacao: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.autorizacaoPendente && token && numeroCPF && codigoValidacao
            && (token !== appState.autorizacaoPendente.token || numeroCPF !== appState.autorizacaoPendente.numeroCPF || codigoValidacao !== appState.autorizacaoPendente.codigoValidacao)
        ) {
            jwtToken()
                .then(jwtToken => {
                    const getOptions: RequestInit = {
                        method: 'GET',
                        headers: {
                            'Authorization': jwtToken
                        }
                    };
                    fetch(apiBase + `AutorizacaoPendente/` + token + `/` + numeroCPF + `/` + codigoValidacao + `/` + ip + `/` + userAgent, getOptions)
                        .then(response => response.json() as Promise<GetAutorizacaoPendente>)
                        .then(data => {
                            dispatch({ type: 'RECEIVE_AUTHORIZATION_VALIDATION', token: token, numeroCPF: numeroCPF, codigoValidacao: codigoValidacao, data: data });
                        })
                        .catch(error => {
                            const errorData: GetAutorizacaoPendente = {
                                indicadorErro: 1,
                                descricaoErro: `Tivemos um probleminha por aqui. Por favor, espere alguns minutos e tente novamente. Se não funcionar, acesse de novo o link que te enviamos no SMS.`
                            }
                            dispatch({ type: 'RECEIVE_AUTHORIZATION_VALIDATION', token: token, numeroCPF: numeroCPF, codigoValidacao: codigoValidacao, data: errorData });
                        });
                })
                .catch(error => {
                    const errorData: GetAutorizacaoPendente = {
                        indicadorErro: 1,
                        descricaoErro: `Tivemos um probleminha por aqui. Por favor, espere alguns minutos e tente novamente. Se não funcionar, acesse de novo o link que te enviamos no SMS.`
                    }
                    dispatch({ type: 'RECEIVE_AUTHORIZATION_VALIDATION', token: token, numeroCPF: numeroCPF, codigoValidacao: codigoValidacao, data: errorData });
                });
            dispatch({ type: 'REQUEST_AUTHORIZATION_VALIDATION', token: token, numeroCPF: numeroCPF, codigoValidacao: codigoValidacao });
        }
    },
    setNavPendente: (page: number, auth: number, selfie?:boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const isDoneSelfie = selfie !== undefined ? selfie : (appState.autorizacaoPendente && appState.autorizacaoPendente.isDoneSelfie);
        dispatch({ type: 'SET_NAV_VALIDATION', currentAuth: auth, currentPage: page, isDoneSelfie: isDoneSelfie });
    },
    requestAutorizacaoConfirmada: (input: PostInputAutorizacaoConfirmada): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.autorizacaoConfirmada && appState.autorizacaoConfirmada.input
            && input && input.token
        ) {
            jwtToken()
                .then(jwtToken => {
                    input.numeroIP = ip;
                    input.conteudoInformacaoNavegador = userAgent;
                    const postOptions: RequestInit = {
                        method: 'POST',
                        // mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': jwtToken
                        },
                        body: JSON.stringify(input)
                    };
                    fetch(apiBase + `AutorizacaoConfirmada`, postOptions)
                        .then(response => response.json() as Promise<PostOutputAutorizacaoConfirmada>)
                        .then(data => {
                            dispatch({ type: 'RECEIVE_AUTHORIZATION_CONFIRMATION', input: input, data: data });
                        })
                        .catch(error => {
                            const errorData: PostOutputAutorizacaoConfirmada = {
                                indicadorErro: 99,
                                descricaoCabecalho: 'Oops...',
                                descricaoErro: `Tivemos um probleminha por aqui. Favor entrar em contato com o seu gerente para verificar se a operação foi realizada com sucesso.`
                            }
                            dispatch({ type: 'RECEIVE_AUTHORIZATION_CONFIRMATION', input: input, data: errorData });
                        });
                })
                .catch(error => {
                    const errorData: PostOutputAutorizacaoConfirmada = {
                        indicadorErro: 1,
                        descricaoCabecalho: 'Oops...',
                        descricaoErro: `Tivemos um probleminha por aqui. Favor entrar em contato com o seu gerente para verificar se a operação foi realizada com sucesso.`
                    }
                    dispatch({ type: 'RECEIVE_AUTHORIZATION_CONFIRMATION', input: input, data: errorData });
                });
            dispatch({ type: 'REQUEST_AUTHORIZATION_CONFIRMATION', input: input });
        }
    },
    requestAutorizacaoNegada: (token: string, indicadorStatus: number, lastPage: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.autorizacaoNegada && token && indicadorStatus && lastPage) {
            jwtToken()
                .then(jwtToken => {
                    const deleteOptions: RequestInit = {
                        method: 'DELETE',
                        // mode: 'cors',
                        headers: {
                            'Authorization': jwtToken
                        }
                    };
                    fetch(apiBase + `AutorizacaoPendente/${token}/${ip}/${userAgent}/${indicadorStatus}`, deleteOptions)
                        .then(response => response.json() as Promise<DeleteAutorizacaoNegada>)
                        .then(data => {
                            data.lastPage = lastPage;
                            data.lastTime = new Date();
                            dispatch({ type: 'RECEIVE_AUTHORIZATION_DENIED', token: token, data: data });
                        })
                        .catch(error => {
                            const errorData: PostOutputAutorizacaoConfirmada = {
                                indicadorErro: 1,
                                descricaoCabecalho: 'Oops...',
                                descricaoErro: `Você desistiu da operação. Se ficou com alguma dúvida ou quando quiser contratar de novo, chama a gente para te ajudar. É sempre um prazer te atender.`
                            };
                            dispatch({ type: 'RECEIVE_AUTHORIZATION_DENIED', token: token, data: errorData });
                        });
                })
                .catch(error => {
                    const errorData: PostOutputAutorizacaoConfirmada = {
                        indicadorErro: 1,
                        descricaoCabecalho: 'Oops...',
                        descricaoErro: `Você desistiu da operação. Se ficou com alguma dúvida ou quando quiser contratar de novo, chama a gente para te ajudar. É sempre um prazer te atender.`
                    }
                    dispatch({ type: 'RECEIVE_AUTHORIZATION_DENIED', token: token, data: errorData });
                });
            dispatch({ type: 'REQUEST_AUTHORIZATION_DENIED', token: token, indicadorStatus: indicadorStatus });
        }
    },
    getSelfie: (token: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        ipData()
            .then(() => {
                if (appState && appState.selfie && token) {
                    jwtToken()
                        .then(jwtToken => {
                            const getOptions: RequestInit = {
                                method: 'GET',
                                headers: {
                                    'Authorization': jwtToken
                                }
                            };
                            fetch(apiBase + `Selfie/${token}`, getOptions)
                                .then(response => {
                                    return response.json() as Promise<GetSelfie>;
                                })
                                .then(data => {
                                    dispatch({ type: 'RECEIVE_SELFIE', token: token, data: data });
                                    const indSelfie: boolean = data.indicadorSelfie ? data.indicadorSelfie > 0 : false;
                                    const indMatch: boolean = data.indicadorMatch ? data.indicadorMatch > 0 : false;
                                    dispatch({ type: 'SET_SELFIE', token: token, indicadorSelfie: indSelfie, indicadorMatch: indMatch, conteudoTextoLGPD: data.conteudoTextoLGPD, conteudoTextoOrientacoes: data.conteudoTextoOrientacoes })
                                })
                                .catch(error => {
                                    const errorData: GetSelfie = {
                                        indicadorErro: 1,
                                        descricaoErro: `Erro ao acessar rota de verificação de Selfie`
                                    }
                                    dispatch({ type: 'RECEIVE_SELFIE', token: token, data: errorData });
                                });
                        })
                        .catch(error => {
                            const errorData: GetSelfie = {
                                indicadorErro: 1,
                                descricaoErro: `Erro ao acessar rota de verificação de Selfie`
                            }
                            dispatch({ type: 'RECEIVE_SELFIE', token: token, data: errorData });
                        });
                    dispatch({ type: 'REQUEST_SELFIE', token: token });
                }
            });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedStateToken: AutorizacaoClienteState = { data: {}, isLoading: false };
export const reducerToken: Reducer<AutorizacaoClienteState> = (state: AutorizacaoClienteState | undefined, incomingAction: Action): AutorizacaoClienteState => {
    if (state === undefined) {
        return unloadedStateToken;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_TOKEN_VALIDATION':
            return {
                token: action.token,
                data: {},
                isLoading: true
            };
        case 'RECEIVE_TOKEN_VALIDATION':
            if (action.token === state.token) {
                return {
                    token: action.token,
                    data: action.data,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};

const unloadedStatePendente: AutorizacaoPendenteState = { data: {}, isLoading: false };
export const reducerPendente: Reducer<AutorizacaoPendenteState> = (state: AutorizacaoPendenteState | undefined, incomingAction: Action): AutorizacaoPendenteState => {
    if (state === undefined) {
        return unloadedStatePendente;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_AUTHORIZATION_VALIDATION':
            return {
                token: action.token,
                numeroCPF: action.numeroCPF,
                codigoValidacao: action.codigoValidacao,
                currentAuth: state.currentAuth,
                currentPage: state.currentPage,
                isDoneSelfie: state.isDoneSelfie,
                indicadorSelfie: state.indicadorSelfie,
                indicadorMatch: state.indicadorMatch,
                data: {},
                isLoading: true,
                currentOperacao: state.currentOperacao
            };
        case 'RECEIVE_AUTHORIZATION_VALIDATION':
            if (action.token === state.token) {
                return {
                    token: action.token,
                    numeroCPF: action.numeroCPF,
                    codigoValidacao: action.codigoValidacao,
                    currentAuth: state.currentAuth,
                    currentPage: state.currentPage,
                    isDoneSelfie: state.isDoneSelfie,
                    indicadorSelfie: state.indicadorSelfie,
                    indicadorMatch: state.indicadorMatch,
                    data: action.data,
                    isLoading: false,
                    currentOperacao: state.currentOperacao
                };
            }
            break;
        case 'SET_NAV_VALIDATION':
            return {
                token: state.token,
                numeroCPF: state.numeroCPF,
                codigoValidacao: state.codigoValidacao,
                currentAuth: action.currentAuth,
                currentPage: action.currentPage,
                isDoneSelfie: action.isDoneSelfie,
                indicadorSelfie: state.indicadorSelfie,
                indicadorMatch: state.indicadorMatch,
                data: state.data,
                isLoading: false
            };
        case 'SET_SELFIE':
            return {
                token: state.token,
                numeroCPF: state.numeroCPF,
                codigoValidacao: state.codigoValidacao,
                currentAuth: state.currentAuth,
                currentPage: state.currentPage,
                isDoneSelfie: state.isDoneSelfie,
                indicadorSelfie: action.indicadorSelfie,
                indicadorMatch: action.indicadorMatch,
                conteudoTextoLGPD: action.conteudoTextoLGPD,
                conteudoTextoOrientacoes: action.conteudoTextoOrientacoes,
                data: state.data,
                isLoading: false
            };
    }

    return state;
};

const unloadedStateConfirmada: AutorizacaoConfirmadaState = { input: {}, data: {}, isLoading: false };
export const reducerConfirmada: Reducer<AutorizacaoConfirmadaState> = (state: AutorizacaoConfirmadaState | undefined, incomingAction: Action): AutorizacaoConfirmadaState => {
    if (state === undefined) {
        return unloadedStateConfirmada;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_AUTHORIZATION_CONFIRMATION':
            return {
                input: action.input,
                data: {},
                isLoading: true
            };
        case 'RECEIVE_AUTHORIZATION_CONFIRMATION':
            if (action.input && state.input && action.input.token === state.input.token) {
                return {
                    input: action.input,
                    data: action.data,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};

const unloadedStateNegada: AutorizacaoNegadaState = { data: {}, isLoading: false };
export const reducerNegada: Reducer<AutorizacaoNegadaState> = (state: AutorizacaoNegadaState | undefined, incomingAction: Action): AutorizacaoNegadaState => {
    if (state === undefined) {
        return unloadedStateNegada;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_AUTHORIZATION_DENIED':
            return {
                token: action.token,
                data: state.data,
                isLoading: true
            };
        case 'RECEIVE_AUTHORIZATION_DENIED':
            if (action.token === state.token) {
                return {
                    token: action.token,
                    data: action.data,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};

const unloadedStateSelfie: SelfieState = { data: {}, isLoading: false };
export const reducerSelfie: Reducer<SelfieState> = (state: SelfieState | undefined, incomingAction: Action): SelfieState => {
    if (state === undefined) {
        return unloadedStateSelfie;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SELFIE':
            return {
                token: action.token,
                data: {},
                isLoading: true
            };
        case 'RECEIVE_SELFIE':
            if (action.token === state.token) {
                return {
                    token: action.token,
                    data: action.data,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};

//async fetch
export const register = async (token: string, tipoAcao: number): Promise<PostOutputAcao | undefined> => {
    if (token && tipoAcao > 0) {
        const responseJwt = await jwtToken();
        const input: PostInputAcao = {
            token: token,
            conteudoInformacaoNavegador: userAgent,
            numeroIP: ip,
            identificadorAcao: tipoAcao
        };
        const postOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': responseJwt
            },
            body: JSON.stringify(input)
        };
        const responseAcao = await (fetch(apiBase + `Acao`, postOptions) as Promise<PostOutputAcao>);
        return responseAcao;
    }
}
export const faceCaptureMatch = async (token: string, imgBase64: string): Promise<PostOutputMatch | undefined> => {
    if (token && imgBase64) {
        const responseJwt = await jwtToken();
        const input: PostInputMatch = {
            token: token,
            userAgent: userAgent,
            numeroIP: ip,
            foto: imgBase64
        };
        const postOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': responseJwt
            },
            body: JSON.stringify(input)
        };
        const response = await (fetch(apiBase + `RealizarMatch`, postOptions));
        const responseAcao: PostOutputMatch = response.json() as PostOutputMatch;
        return responseAcao;
    }
}
export const armazenarFoto = async (token: string, imgBase64: string, indicadorMatch: boolean): Promise<PostOutputArmazenarFoto | undefined> => {
    if (token && imgBase64) {
        const responseJwt = await jwtToken();
        const input: PostInputArmazenarFoto = {
            token: token,
            indicadorMatch: indicadorMatch ? "S" : "N",
            userAgent: userAgent,
            numeroIP: ip,
            foto: imgBase64
        };
        const postOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': responseJwt
            },
            body: JSON.stringify(input)
        };
        const response = await (fetch(apiBase + `ArmazenarFoto`, postOptions));
        const responseAcao: PostOutputArmazenarFoto = response.json() as PostOutputArmazenarFoto;
        return responseAcao;
    }
}