interface oAuthInput {
    grant_type: string,
    client_id: string,
    client_secret: string
}

interface oAuthOutput {
    access_token: string,
    token_type: string,
    expires_in: number,
    scope: string
}

interface jwtUtilInput {
    mbData: mbData,
    tokenSubject: "MB.UI.PLA.Autoriza"
}
interface mbData {
    token: string,
    ipAddress: string,
    userAgent: string
}

interface jwtUtilOutput {
    access_token: string
}

export interface JwtTokenData {
    expiry?: Date;
    token?: string;
}

const JwtUtil = () => {
    const GetOAuthToken = async () => {
        const oAuthUrl: string = process.env.REACT_APP_JWT_TOKEN_URL || "";

        let result: oAuthOutput = {
            access_token: "",
            token_type: "",
            expires_in: 0,
            scope: ""
        };
        try {
            const oAuthInstance: oAuthInput = {
                grant_type: "client_credentials",
                client_id: process.env.REACT_APP_JWT_TOKEN_CLIENT_ID || "",
                client_secret: process.env.REACT_APP_JWT_TOKEN_SECRET || ""
            }
            const PostOptions: RequestInit = {
                method: "POST",
                //mode: 'cors',
                body: new URLSearchParams({
                    'grant_type': oAuthInstance.grant_type,
                    'client_id': oAuthInstance.client_id,
                    'client_secret': oAuthInstance.client_secret
                }),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
            const response = await fetch(oAuthUrl, PostOptions);
            const json = await response.json();
            result = json;
        } catch { }
        return result;
    };
    const GetJwtToken = async (oAuthToken: oAuthOutput) => {
        const jwtUtilUrl: string = process.env.REACT_APP_JWT_UTIL_URL || "";
        let result: string = "";
        try {
            let data: jwtUtilInput = {
                mbData: {
                    ipAddress: "127.0.0.1",
                    token: "MB.PLA.UI.AutorizacaoCliente",
                    userAgent: "teste"
                },
                tokenSubject: "MB.UI.PLA.Autoriza"
            };

            if (oAuthToken) {
                const PostOptions: RequestInit = {
                    method: "POST",
                    mode: 'cors',
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${oAuthToken.access_token}`
                    }
                }
                const response = await fetch(jwtUtilUrl, PostOptions);
                const json = await response.json();
                result = json.access_token;
            }
        } catch { }
        return result ? result : "";
    };
    const GetFixed = async () => {
        const oAuthToken = await GetOAuthToken();
        const jwtToken = await GetJwtToken(oAuthToken);

        const tokenData: JwtTokenData = {
            expiry: new Date(new Date().getTime() + (oAuthToken.expires_in - 5) * 1000),
            token: `Bearer ${jwtToken}`
        };
        // const tokenDataFixed: JwtTokenData = {
        //     expiry: new Date(),
        //     token: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IkRFRkFVTFQifQ.ew0KICAiaXNzIjogImFwaWRldi5tZXJjYW50aWwuY29tLmJyIiwNCiAgInN1YiI6ICJNQi5VSS5QTEEuQXV0b3JpemEiLA0KICAiYXVkIjogIkFQUCBBdXRvcml6YSBBw60tQmFuY28gTWVyY2FudGlsIGRvIEJyYXNpbCIsDQogICJleHAiOiAxNjMwNDk5MDgwLA0KICAiaWF0IjogMTYzMDQ5NzI4MCwNCiAgIm5iZiI6IDE2MzA0OTcyODAsDQogICJtYi5kYXRhIjogeyJpcEFkZHJlc3MiOiIxMjcuMC4wLjEiLCJ1c2VyQWdlbnQiOiJNb3ppbGxhJTJmNS4wKyhXaW5kb3dzK05UKzEwLjAlM2IrV2luNjQlM2IreDY0KStBcHBsZVdlYktpdCUyZjUzNy4zNisoS0hUTUwlMmMrbGlrZStHZWNrbykrQ2hyb21lJTJmOTIuMC40NTE1LjEwNytTYWZhcmklMmY1MzcuMzYiLCJpZCI6MSwidG9rZW4iOiJ5YWRhIHlhZGEifSwNCiAgIm1iLnBlcm1pc3Npb25zIjoge30NCn0.MSvcgPg5RxYWfNaDal_kRvaYgDINBSeVddu6h9xI_fOD6Muw3zQ6hITucwLUMiZ7ZSW9L2YGjplcK-Iw5la1OmJhLG_-tEGpBsUNnLzORUzbb_K-amApFeLfWbw9ouG5YaCObdRcE91nELJqIMwzFhfL39az8lTJM6cNC9Q7npopOOvR4QRh7NDp0WID6IqNTPZ5BK4Qwa39eDfCGHOWOpq8SA9IfZooy2lFtAb0WRH9a7hGbBTK64QYXW8bTwVfkjNkQqaHkervYBbErysM908mYpf7uoGjEzmqGCmXEtTiGrBWY0HbYSsIDOlbKTSsO4h56HajcWpdH3S62R4crQ"
        // };
        return tokenData;
    }

    return GetFixed();
}

export default JwtUtil;
