import * as React from 'react';
import { connect } from 'react-redux';
import '../Layout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class LinkInvalido extends React.PureComponent {
    // This method is called when the component is first added to the document
    public componentDidMount() {
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
    }

    //Render
    public render() {
        return (
            <React.Fragment>
                <div className="text-center card-title">
                    <h3 className="title">
                        <FontAwesomeIcon icon={["far", "times-circle"]} /> Link inválido
                    </h3>
                </div>
                <div className="mt-3 mt-3">
                    <p>
                        Você está acessando um <strong>link inválido</strong> ou <strong>expirado</strong>.
                        <br />
                        Favor entrar em contato com o seu gerente do MB para que seja enviado um novo link para você.
                    </p>
                </div>
            </React.Fragment>
        );
    }
}

export default connect()(LinkInvalido as any);
