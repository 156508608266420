import * as AutorizacaoCliente from './AutorizacaoCliente';

// The top-level state object
export interface ApplicationState {
    autorizacaoCliente: AutorizacaoCliente.AutorizacaoClienteState | undefined;
    autorizacaoPendente: AutorizacaoCliente.AutorizacaoPendenteState | undefined;
    autorizacaoConfirmada: AutorizacaoCliente.AutorizacaoConfirmadaState | undefined;
    autorizacaoNegada: AutorizacaoCliente.AutorizacaoNegadaState | undefined;
    selfie: AutorizacaoCliente.SelfieState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    autorizacaoCliente: AutorizacaoCliente.reducerToken,
    autorizacaoPendente: AutorizacaoCliente.reducerPendente,
    autorizacaoConfirmada: AutorizacaoCliente.reducerConfirmada,
    autorizacaoNegada: AutorizacaoCliente.reducerNegada,
    selfie: AutorizacaoCliente.reducerSelfie
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
