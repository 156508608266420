import * as React from 'react';
import { connect } from 'react-redux';

const Fechamento = () => (<>
    <div className="text-center card-title">
        <h3 className="title">Obrigado.</h3>
    </div>
    <div className="mt-3" />
    <div className="mb-3 text-justify">
        <p>
            Agradecemos o seu contato. Em caso de dúvidas, seguem os canais de contato com o Banco Mercantil:
        </p>
        <p>
            Alô, Mercantil: 0800 70 70 398 SAC<br />
            WhatsApp: 0800 70 70 398<br />
            Ouvidoria: 0800 70 70 384
        </p>
    </div>
</>);

export default connect()(Fechamento);
