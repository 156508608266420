import * as React from 'react';

export default (props: {}) => (
    <React.Fragment>
        <div className="text-center card-title">
            <h3 className="title">Aguarde...</h3>
        </div>
        <div className="loading">
            Carregando...
        </div>
    </React.Fragment>
);
