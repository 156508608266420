import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as AutorizacaoClienteStore from '../../store/AutorizacaoCliente';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../Layout.css';

import Loading from '../Loading'
import { UnregisterCallback } from 'history';

type AutorizacaoNegadaProps =
    AutorizacaoClienteStore.AutorizacaoNegadaState
    & typeof AutorizacaoClienteStore.actionCreators
    & RouteComponentProps<{ token: string }>;

type DesistenciaState = {
    isValidated: boolean,
    numeroCPF?: string,
    codigoValidacao?: string,
}

class FaceMatchInvalido extends React.PureComponent<AutorizacaoNegadaProps, DesistenciaState> {
    currentPathname: string;
    currentSearch: string;
    unregisterHistoryListener: UnregisterCallback | null;
    constructor(props: Readonly<AutorizacaoNegadaProps>) {
        super(props);
        this.state = { isValidated: false };
        this.currentPathname = '';
        this.currentSearch = '';
        this.unregisterHistoryListener = null;
    }

    public componentDidMount() {
        if (this.unregisterHistoryListener === null) {
            this.unregisterHistoryListener = this.props.history.listen((newLocation, action) => {
                if (action === "PUSH") {
                    if (newLocation.pathname !== this.currentPathname || newLocation.search !== this.currentSearch) {
                        this.currentPathname = newLocation.pathname;
                        this.currentSearch = newLocation.search;
                        this.props.history.push({
                            pathname: newLocation.pathname,
                            search: newLocation.search
                        });
                    }
                } else {
                    this.props.history.go(1);
                }
            });
        }
    }
    public componentWillUnmount() {
        if (this.unregisterHistoryListener) {
            this.unregisterHistoryListener();
        }
    }

    public componentDidUpdate() {
    }

    public render = () => {
        return (<>
            {this.props.isLoading && <>
                <Loading />
            </>}
            {!this.props.isLoading && this.props.data && this.props.data.indicadorErro !== undefined && this.props.data.indicadorErro === 0 && <>
                <div className="text-center card-title">
                    <h3 className="title">
                        <FontAwesomeIcon icon={["far", "frown"]} /> Que pena!
                    </h3>
                </div>
                <div className="mt-3" />
                <div className="mb-3 text-justify">
                    <p>
                        Falha no Reconhecimento Facial. Selfie não corresponde ao cadastro no MB.
                    </p>
                </div>
            </>}
            {!this.props.isLoading && this.props.data && this.props.data.indicadorErro !== undefined && this.props.data.indicadorErro !== 0 && <>
                <div className="text-center card-title">
                    <h3 className="title">Oops...</h3>
                </div>
                <div className="mt-3" />
                <div className="mb-3">
                    {this.props.data && this.props.data.descricaoErro}
                </div>
            </>}
        </>);
    }
}
export default connect(
    (state: ApplicationState) => state.autorizacaoNegada,
    AutorizacaoClienteStore.actionCreators
)(FaceMatchInvalido as any);
