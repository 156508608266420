import * as React from 'react';
import Webcam from 'react-webcam';
import Lottie from 'react-lottie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './selfiecam.css';
import * as FaceMath from './face-math';
import Loading from '../Loading'

import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import { MediaPipeFaceMesh } from "@tensorflow-models/face-landmarks-detection/dist/types";

import loading from '../../assets/arrows/51681-loading-dots.json';
import faceCnt from '../../assets/arrows/00002-face-center.json';
import arrowUp from '../../assets/arrows/11264-swipe-up-arrows.json';
import arrowDw from '../../assets/arrows/11514-swipe-down-arrows.json';
import arrowLf from '../../assets/arrows/11516-swipe-left-arrows.json';
import arrowRg from '../../assets/arrows/11515-swipe-right-arrows.json';
import { AnnotatedPrediction } from '@tensorflow-models/face-landmarks-detection/dist/mediapipe-facemesh';
import { armazenarFoto, faceCaptureMatch, register } from '../../store/AutorizacaoCliente';

type WebcamStreamCaptureProps = {
    token?: string;
    verifyMatch?: boolean;
    conteudoLGPD?: string;
    conteudoOrientacoes?: string;
    onDone?: (imgSrc: string | undefined, idOnBase: number | undefined) => void;
    onCancel?: (descricaoErro: string | undefined) => void;
    onInvalid?: (descricaoErro: string | undefined) => void;
}

const WebcamStreamCapture: React.FC<WebcamStreamCaptureProps> = (props) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isCameraBlocked, setIsCameraBlocked] = React.useState<boolean>(false);
    const [phase, setPhase] = React.useState<number>(0);

    let webcamRef = React.useRef<Webcam>(null);
    const [videoLoaded, setVideoLoaded] = React.useState<boolean>(false);

    const animCanvasRef = React.useRef<Lottie>(null);
    const [animHeight, setAnimHeight] = React.useState<string>('100%');
    const [animWidth, setAnimWidth] = React.useState<string>('100%');
    const [anim, setAnim] = React.useState<any>(null);

    const audioUp = React.useRef<HTMLAudioElement>(null);
    const audioDw = React.useRef<HTMLAudioElement>(null);
    const audioLf = React.useRef<HTMLAudioElement>(null);
    const audioRg = React.useRef<HTMLAudioElement>(null);
    const [audioSelected, setAudioSelected] = React.useState<string>('');

    const [snap, setSnap] = React.useState<string>('');
    const [model, setModel] = React.useState<MediaPipeFaceMesh | null>(null);
    const [faceDetected, setFaceDetected] = React.useState<boolean>(false);
    const [faceActions, setFaceActions] = React.useState<FaceMath.FaceAction[]>([]);

    const [isTracking, setIsTracking] = React.useState<boolean>(false);
    const [isCheckingFrame, setIsCheckingFrame] = React.useState<boolean>(false);
    const [currentAction, setCurrentAction] = React.useState<number>(0);

    const [isDone, setIsDone] = React.useState<boolean>(false);

    let matchTries: number = 0;
    const [descricaoErroMatch, setDescricaoErroMatch] = React.useState<string>('');

    React.useEffect(
        () => {
            if (webcamRef && webcamRef.current) {
                const video = webcamRef.current.video;
                if (video) {
                    setAnimHeight(`${video.offsetHeight}px`);
                    setAnimWidth(`${video.offsetWidth}px`);
                }
            }
        },
        [webcamRef, videoLoaded]
    );

    const capture = () => {
        if (webcamRef && webcamRef.current) {
            const tempVal = webcamRef.current.getScreenshot();
            setSnap(tempVal ? tempVal : '');
        }
    };

    const changeAnim = (id: string) => {
        if (id !== audioSelected) {
            switch (id) {
                case 'up': setAnim(arrowUp); audioUp.current && audioUp.current.play(); break;
                case 'dw': setAnim(arrowDw); audioDw.current && audioDw.current.play(); break;
                case 'lf': setAnim(arrowLf); audioLf.current && audioLf.current.play(); break;
                case 'rg': setAnim(arrowRg); audioRg.current && audioRg.current.play(); break;
            }
            setAudioSelected(id);
            const canvas = animCanvasRef.current;
            if (canvas) {
                canvas.forceUpdate();
            }
        }
    }

    const detect = async (model: MediaPipeFaceMesh) => {
        if (webcamRef.current && webcamRef.current.video && webcamRef.current.video.readyState === 4) {
            const predictions: AnnotatedPrediction[] = await model.estimateFaces({
                input: webcamRef.current.video,
                predictIrises: false,
            });
            switch (predictions.length) {
                case 1:
                    setFaceDetected(predictions[0].faceInViewConfidence > 0.9);
                    const landmarks = predictions[0].scaledMesh;
                    const width: number = webcamRef.current.video ? webcamRef.current.video.videoWidth : 0;
                    const height: number = webcamRef.current.video ? webcamRef.current.video.videoHeight : 0;
                    if (predictions[0].faceInViewConfidence > 0.9) {
                        setIsTracking(true);
                        let faceAction: number = 0;
                        if (faceActions.length > 0 && currentAction < faceActions.length) {
                            faceAction = faceActions[currentAction].actionId;
                        } else if (faceActions.length > 0 && currentAction >= faceActions.length) {
                            faceAction = 5;
                        }
                        switch (faceAction) {
                            case 0:
                                changeAnim('cnt');
                                setAnim(faceCnt);
                                if (faceActions.length === 0) {
                                    const temp: FaceMath.FaceAction[] = FaceMath.getFaceActions();
                                    setFaceActions(temp);
                                }

                                const isCentered = FaceMath.isCentered(landmarks, width, height);
                                if (isCentered) {
                                    capture();
                                    setCurrentAction(1);
                                }
                                break;
                            case 1:
                                changeAnim('lf');
                                const isLeft = FaceMath.isLeftFaced(landmarks, width, height);
                                if (isLeft) setCurrentAction(currentAction + 1);
                                break;
                            case 2:
                                changeAnim('rg');
                                const isRight = FaceMath.isRightFaced(landmarks, width, height);
                                if (isRight) setCurrentAction(currentAction + 1);
                                break;
                            case 3:
                                changeAnim('up');
                                const isUp = FaceMath.isUpFaced(landmarks, width, height);
                                if (isUp) setCurrentAction(currentAction + 1);
                                break;
                            case 4:
                                changeAnim('dw');
                                const isDown = FaceMath.isDownFaced(landmarks, width, height);
                                if (isDown) setCurrentAction(currentAction + 1);
                                break;
                            case 5:
                                changeAnim('');
                                setIsDone(true);
                                setAudioSelected('');
                                setIsTracking(false);
                                setFaceDetected(false);
                                setAnim(null);
                                setVideoLoaded(false);
                                setPhase(3);
                                break;
                        }
                    }
                    break;
                default:
                    setFaceDetected(false);
                    setCurrentAction(0);
                    setFaceActions([]);
                    //TODO: set warning no face detected
                    break;
            }
        };
        setIsCheckingFrame(false);
    };

    const finalize = async () => {
        const imgbase64: string = snap.substring(snap.indexOf(',') + 1);
        if (props.token) {
            setIsLoading(true);
            if (props.verifyMatch) {
                const response = await faceCaptureMatch(props.token, imgbase64);
                const matchSucceeded: boolean = response !== undefined && response.indicadorErro === 0;
                const responseFoto = await armazenarFoto(props.token, imgbase64, matchSucceeded);
                const idOnBase = responseFoto && responseFoto.identificadorOnBase && responseFoto.identificadorOnBase > 0 ? responseFoto.identificadorOnBase : 0;
                if (matchSucceeded) {
                    invokeDoneCallback(idOnBase);
                } else if (response && (response.indicadorErro === 1 || response.indicadorErro === 2)) {
                    if (matchTries < 2) {
                        matchTries++;
                        setDescricaoErroMatch(response.descricaoErro ? response.descricaoErro : '');
                        setPhase(4);
                    } else {
                        invokeCancelCallback('o número máximo de tentativas de envio de selfie foi atingido.');
                        if (props.token) {
                            await register(props.token, 17);
                        }
                    }
                } else {
                    const defaultMsg = 'Não foi possível realizar sua contratação por esse canal. Entre em contato com nosso call center ou visite seu ponto de atendimento para finalizar a contratação';
                    setDescricaoErroMatch(response && response.descricaoErro ? response.descricaoErro : defaultMsg);
                    setPhase(5);
                    invokeInvalidCallback('FaceMatch API retornou erro não retornável.');
                }
            } else {
                const responseFoto = await armazenarFoto(props.token, imgbase64, false);
                const idOnBase = responseFoto && responseFoto.identificadorOnBase && responseFoto.identificadorOnBase > 0 ? responseFoto.identificadorOnBase : 0;
                invokeDoneCallback(idOnBase);
            }
            setIsLoading(false);
            await register(props.token, 12);
        }
    }

    const reset = async () => {
        if (props.token) {
            await register(props.token, 13);
        }
        setIsDone(false);
        setCurrentAction(0);
        setPhase(2);
    }

    const invokeDoneCallback = async (idOnBase: number) => {
        if (props && props.onDone) {
            props.onDone(snap, idOnBase);
        }
        if (props.token) {
            await register(props.token, 14);
        }
    }

    const invokeCancelCallback = (descricaoErro: string | undefined) => {
        if (props && props.onCancel) {
            props.onCancel(descricaoErro);
        }
    }

    const invokeInvalidCallback = async (descricaoErro: string | undefined) => {
        if (props && props.onInvalid) {
            props.onInvalid(descricaoErro);
        }
        if (props.token) {
            await register(props.token, 15);
        }
    }

    const readyState = webcamRef && webcamRef.current && webcamRef.current.video && webcamRef.current.video.readyState;
    React.useEffect(
        () => {
            if (webcamRef && webcamRef.current && webcamRef.current.video && webcamRef.current.video.readyState === 4) {
                setAnim(loading);
                if (!isTracking && !isDone) {
                    faceLandmarksDetection.load(
                        faceLandmarksDetection.SupportedPackages.mediapipeFacemesh
                    ).then((response) => {
                        setModel(response);
                    });
                }
            }
        },
        [readyState, isTracking, isDone]
    );

    React.useEffect(
        () => {
            if (model !== null && !isCheckingFrame) {
                setIsCheckingFrame(true);
                detect(model);
                // let timer = window.setTimeout(() => { detect(model) }, 10);
                // return () => { clearTimeout(timer); }
            }
        },
        [model, isCheckingFrame]
    );

    const iniciarFaceCapture = async () => {
        navigator.mediaDevices.getUserMedia({ video: true }).then(async (response) => {
            if (response.getVideoTracks && response.getVideoTracks().length) {
                if (props.token) {
                    await register(props.token, 19);
                }
                setPhase(1);
            } else {
                setIsCameraBlocked(true);
                if (props.token) {
                    await register(props.token, 20);
                }
            }
        }).catch(async (error) => {
            setIsCameraBlocked(true);
            if (props.token) {
                await register(props.token, 20);
            }
        });
    }
    const desistirFaceCapture = async () => {
        if (props.token) {
            await register(props.token, 16);
            invokeCancelCallback(undefined);
        }
    }

    const iniciarCameraRecording = async () => {
        if (props.token) {
            await register(props.token, 18);
        }
        setPhase(2);
    }

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && <>
                {phase === 0 &&
                    <div className="selfie-disclaimer">
                        <div className="text-center card-title">
                            <h3 className="title">
                                Reconhecimento Facial
                            </h3>
                        </div>
                        <div className="mt-3 mt-3">
                            {props.conteudoLGPD && <div dangerouslySetInnerHTML={{ __html: props.conteudoLGPD }}></div>}
                            {!props.conteudoLGPD && <>
                                <p className="text-center">
                                    <strong>Para continuar, precisamos realizar seu reconhecimento facial:</strong>
                                </p>
                                <p className="text-justify">
                                    Ao prosseguir, você concorda em permitir a captura dos seus dados para utilização no processo de validação biométrica facial <strong>para a contratação de Empréstimo</strong> junto ao <strong>Banco Mercantil</strong> para o combate à fraude e sua identificação quando necessário.
                                </p>
                            </>}
                            {isCameraBlocked && <>
                                <div className='alert'>
                                    Para prosseguir, por favor conceda-nos a permissão de acesso à camera do seu dispositivo.
                                </div>
                            </>}
                        </div>
                        <button type='button' className='btn btn-primary' onClick={iniciarFaceCapture}>Aceitar</button>
                        <button type="button" className="btn btn-cancel" onClick={desistirFaceCapture} tabIndex={4}>Desistir</button>
                        {/* <button type='button' className='btn btn-primary' onClick={invokeDoneCallback}>Cheat</button> */}
                    </div>
                }
                {phase === 1 &&
                    <div className="selfie-tips">
                        <div className="text-center card-title">
                            <h3 className="title">
                                Dicas para o Reconhecimento Facial
                            </h3>
                        </div>
                        <div className="mt-3 mt-3 text-justify">
                            {props.conteudoOrientacoes && <div dangerouslySetInnerHTML={{ __html: props.conteudoOrientacoes }}></div>}
                            {!props.conteudoOrientacoes && <>
                                <ul>
                                    <li>
                                        <span>Esteja em um ambiente bem iluminado e sem pessoas e/ou objetos ao fundo.</span>
                                    </li>
                                    <li>
                                        <span>Deixe o seu rosto bem visível. Não utilize acessórios, como: boné, chapéu, óculos ou quaisquer itens que possam cubrir parte do seu rosto.</span>
                                    </li>
                                    <li>
                                        <span >Apoie o celular numa superfície ou segure firmemente o aparelho na altura do rosto.</span >
                                    </li>
                                    <li>
                                        <span >Siga atentamente as instruções e realize os movimentos que serão solicitados.</span >
                                    </li>
                                </ul>
                            </>}
                        </div>
                        <button type='button' className='btn btn-primary' onClick={iniciarCameraRecording}>Prosseguir</button>
                    </div>
                }
                {phase === 2 &&
                    <>
                        <div className="text-center card-title">
                            <h3 className="title">
                                Reconhecimento Facial
                            </h3>
                        </div>
                        <div className="selfie-cam-wrapper">
                            <Webcam
                                id="selfie-cam-video"
                                audio={false}
                                mirrored={false}
                                screenshotFormat="image/jpeg"
                                ref={webcamRef}
                                onPlay={() => { setVideoLoaded(true); }}
                                videoConstraints={{ facingMode: "user" }}
                            />
                        </div>
                        <div className="selfie-cam-wrapper">
                            <div className="lottie-wrapper" style={{ width: animWidth, height: animHeight }}>
                                <Lottie
                                    ref={animCanvasRef}
                                    height={'100%'}
                                    width={'100%'}
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: anim,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}
                                />
                            </div>
                            <br />
                            <div className="selfie-cam-actions">
                                <FontAwesomeIcon icon={["far", currentAction >= 1 ? "check-square" : "square"]} />
                                <FontAwesomeIcon icon={["far", currentAction >= 2 ? "check-square" : "square"]} />
                                <FontAwesomeIcon icon={["far", currentAction >= 3 ? "check-square" : "square"]} />
                                <FontAwesomeIcon icon={["far", currentAction >= 4 ? "check-square" : "square"]} />
                                <p>
                                    {(readyState === null || (readyState > 0 && readyState !== 4)) && 'Ainda não conseguimos acessar sua câmera. Verifique se você concedeu permissão de acesso à câmera para este aplicativo ou se sua câmera está em uso em outra aplicação.'}
                                    {readyState === 4 && !isTracking && 'Carregando...'}
                                    {isTracking && faceDetected && faceActions[currentAction] && <>
                                        <div className={`selfie-cam-actions-descriptions ${audioSelected}`}>
                                            <span>{faceActions[currentAction].msg}</span>
                                            <img src='/imgs/slide-arrow.svg' alt='' className='slide-arrow'></img>
                                        </div>
                                    </>}
                                    {isTracking && !faceDetected ? 'Perdemos o seu rosto. Por favor, centralize-o!' : ''}
                                </p>
                            </div>
                        </div>
                    </>
                }
                {phase === 3 &&
                    <div className='selfie-confirmation'>
                        <div className="text-center card-title">
                            <h3 className="title">
                                Confirmar o Reconhecimento Facial
                            </h3>
                        </div>
                        <img src={snap} alt="captured snapshot" style={{ display: currentAction < 4 ? 'none' : '' }} />
                        <br />
                        <p className='text-center'>Sua foto ficou nítida?</p>
                        <button type='button' className='btn btn-primary' onClick={finalize}>Sim, aceitar selfie</button>
                        <button type='button' className='btn btn-cancel' onClick={reset}>Não, reiniciar reconhecimento facial</button>
                    </div>
                }
                {phase === 4 &&
                    <div className='selfie-confirmation'>
                        <div className="text-center card-title">
                            <h3 className="title">
                                Falha no Reconhecimento Facial
                            </h3>
                        </div>
                        <p className='text-center'>
                            {descricaoErroMatch}
                        </p>
                        <div className="mt-3 mt-3 text-justify">
                            <ul>
                                <li>
                                    <span>Esteja em um ambiente bem iluminado e sem pessoas e/ou objetos ao fundo.</span>
                                </li>
                                <li>
                                    <span>Deixe o seu rosto bem visível. Não utilize acessórios, como: boné, chapéu, óculos ou quaisquer itens que possam cubrir parte do seu rosto.</span>
                                </li>
                                <li>
                                    <span >Apoie o celular numa superfície ou segure firmemente o aparelho na altura do rosto.</span >
                                </li>
                                <li>
                                    <span >Siga atentamente as instruções e realize os movimentos que serão solicitados.</span >
                                </li>
                            </ul>
                        </div>
                        <button type='button' className='btn btn-primary' onClick={() => { matchTries++; reset(); }}>Sim, reiniciar reconhecimento facial</button>
                        <button type='button' className='btn btn-cancel' onClick={desistirFaceCapture}>Não, desistir do contrato</button>
                    </div>
                }
                {phase === 5 &&
                    <div className='selfie-confirmation'>
                        <div className="text-center card-title">
                            <h3 className="title">Oops...</h3>
                        </div>
                        <p className='text-center'>
                            {descricaoErroMatch}
                        </p>
                    </div>
                }
            </>}
            <audio ref={audioUp}><source src='/audios/MercantilCima_3.mp3'></source></audio>
            <audio ref={audioDw}><source src='/audios/MercantilBaixo_3.mp3'></source></audio>
            <audio ref={audioLf}><source src='/audios/MercantilEsquerda_3.mp3'></source></audio>
            <audio ref={audioRg}><source src='/audios/MercantilDireita_3.mp3'></source></audio>
        </>
    );
};

export default WebcamStreamCapture;