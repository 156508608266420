import * as React from 'react';
import './Layout.css';

export default (props: { children?: React.ReactNode}) => (
    <React.Fragment>
        <div className="azul">
            <div className="d-flex justify-content-center align-items-center">
                <svg width="160" height="50" viewBox="0 0 126 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m75.44,24.73c.04.11.04.19,0,.25-.01.02-.05.07-.17.07h-4c-.07,0-.12-.02-.16-.06-.03-.03-.06-.1-.03-.25l2.01-5.49c.04-.1.1-.16.16-.16.05,0,.11.04.14.16l2.05,5.49Zm4.23,3.5l-4.47-11.65c-.15-.39-.5-.64-.9-.64h-2.1c-.4,0-.75.25-.9.64l-4.69,12.35-.06.17c-.12.32.12.66.46.66h1.91s.8-2.36.8-2.36h.48s6.21,0,6.21,0h.43l.79,2.28.02.08h1.9c.34,0,.58-.34.46-.66l-.33-.88Z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m66.17,25.86l-1.06-.87c-.13-.1-.26-.15-.39-.13-.16.02-.3.15-.38.25v.02c-.49.76-1.09,1.32-1.8,1.67-.71.35-1.63.53-2.72.53-1.39,0-2.49-.33-3.28-.99-.79-.65-1.17-1.67-1.17-3.09v-.78c0-2.69,1.5-4.06,4.45-4.06,2,0,3.47.68,4.36,2.04l.02.02c.25.27.52.29.79.06l1.04-.97c.12-.1.19-.21.21-.33.02-.13-.02-.27-.12-.41-1.19-1.92-3.31-2.89-6.3-2.89-4.61,0-6.85,2.15-6.85,6.56v.74c0,4.43,2.24,6.58,6.85,6.58,2.96,0,5.13-1.06,6.43-3.15l.02-.03c.09-.11.12-.24.1-.4-.02-.16-.09-.28-.22-.38"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m93.4,15.94h-1.45c-.14,0-.25.05-.35.16-.1.11-.15.25-.15.41v7.7c0,.28-.19.45-.36.51-.17.06-.42.05-.6-.17l-6.71-8.45c-.06-.1-.16-.15-.31-.15h-1.79c-.13,0-.25.05-.34.16-.09.11-.14.25-.14.41v12.7c0,.16.05.3.14.41.09.11.21.16.34.16h1.47c.14,0,.25-.05.35-.16.1-.11.15-.25.15-.41v-9.26s7.67,9.68,7.67,9.68c.06.1.17.16.31.16h1.77c.13,0,.25-.05.34-.16.1-.11.15-.25.15-.41v-12.7c0-.17-.05-.31-.16-.42-.1-.1-.21-.16-.34-.16"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m110.46,15.94h-1.53c-.13,0-.24.05-.34.16-.09.11-.14.25-.14.41v12.7c0,.16.05.3.14.41.09.11.2.16.34.16h1.53c.14,0,.25-.06.34-.16.09-.11.14-.25.14-.41v-12.7c0-.16-.05-.31-.14-.41-.09-.1-.2-.16-.34-.16"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m121.84,27.35c-.1-.11-.22-.16-.35-.16h-5.57c-.42,0-.77-.35-.77-.77v-9.91c0-.16-.05-.3-.14-.41-.09-.1-.2-.16-.34-.16h-1.53c-.13,0-.24.06-.34.16-.1.11-.14.25-.14.41v12.7c0,.16.05.3.14.41.09.11.21.16.34.16h8.34c.14,0,.26-.06.35-.16.1-.11.16-.25.16-.41v-1.45c0-.16-.05-.3-.16-.41"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m49.5,20.43c0,.78-.2,1.34-.62,1.73-.42.39-1.02.59-1.78.59h-5.12s0-3.54,0-3.54c.01-.12.11-.75.78-.75h4.34c.8,0,1.41.15,1.81.44.4.28.59.78.59,1.53m2.5,0c0-1.61-.4-2.78-1.19-3.47-.78-.68-1.97-1.02-3.54-1.02h-7.25c-.13,0-.24.05-.34.16-.09.11-.14.25-.14.41v12.7c0,.16.05.3.14.41.09.11.2.16.34.16h1.46c.13,0,.25-.05.34-.16.1-.11.15-.25.15-.41v-4s4.47,0,4.47,0l2.81,4.19c.18.26.43.38.75.38h1.21c.26,0,.45-.12.56-.36.11-.25.07-.51-.1-.74l-2.56-3.78.25-.09c1.75-.62,2.64-2.09,2.64-4.38"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m106.88,16.17h0l.08-.08c-.1-.1-.21-.16-.34-.16h-11.12c-.13,0-.25.05-.34.16-.1.11-.15.25-.15.41v1.4c0,.17.05.3.16.41.1.09.21.14.34.14h4.32v10.75c0,.16.05.3.15.41.1.11.21.16.34.16h1.51c.14,0,.25-.05.35-.16.1-.11.15-.25.15-.41v-10.04s0-.03,0-.03c.08-.43.36-.67.77-.67h3.53c.13,0,.25-.05.34-.15.1-.11.16-.24.16-.4v-1.4c0-.17-.05-.31-.16-.42l-.08.08Z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m37.94,27.18h-6.63v-2.99h2.06c.22,0,.4-.21.4-.46v-1.86c0-.26-.18-.46-.4-.46h-2.06v-2.26c.08-.44.36-.69.77-.69h5.79c.13,0,.24-.05.33-.15.1-.11.15-.24.15-.4v-1.4c0-.16-.05-.3-.14-.41-.09-.1-.2-.16-.33-.16h-8.54c-.14,0-.28.06-.36.17-.09.11-.14.25-.14.4v4.9h-1.97c-.22,0-.4.21-.4.46v1.86c0,.26.18.46.4.46h1.96v.21s.01,4.52.01,4.52c0,.32.06.53.18.67.04.05.17.19.58.19h8.36c.13,0,.25-.05.34-.15.1-.11.16-.28.16-.46v-1.4c0-.17-.05-.31-.16-.42-.1-.11-.21-.16-.34-.16"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m24.32,15.94h-1.77c-.15,0-.25.05-.31.15l-6.88,8.69c-.12.15-.3.2-.47.14-.18-.06-.3-.24-.3-.44v-7.97c0-.16-.05-.3-.15-.41-.1-.11-.21-.16-.34-.16h-1.77c-.15,0-.25.05-.31.15l-7.78,9.62c-.13.16-.2.36-.2.57l-.04,3.03c0,.19.08.32.2.37.1.04.21,0,.3-.09l6.85-8.62c.12-.15.3-.2.47-.14.18.06.3.24.3.44v7.93c0,.15.04.29.11.39.1.13.22.2.37.2h1.78c.18,0,.25-.07.35-.18l6.87-8.65c.11-.14.3-.2.47-.14.18.06.3.24.3.44v7.93c0,.18.06.35.16.46.09.1.21.15.34.15h1.43c.13,0,.24-.04.33-.12.13-.11.19-.27.19-.45v-12.7c0-.16-.05-.3-.15-.41-.1-.11-.21-.16-.34-.16"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m14.99,12.45c.34,0,.51-.14.51-.41,0-.24-.15-.37-.46-.37h-1.88s-.07.02-.07.07v.64s.02.07.07.07h1.84Zm-.24-1.5c.27,0,.45-.15.45-.37s-.14-.33-.41-.33h-1.63s-.07.02-.07.07v.57s.02.07.07.07h1.6Zm-2.51,2.46c-.11,0-.18-.08-.18-.18v-3.75c0-.11.08-.18.18-.18h2.73c.82,0,1.28.36,1.28,1.02,0,.39-.15.68-.45.87-.04.03-.04.06,0,.07.46.13.74.48.74.94,0,.81-.51,1.21-1.38,1.21h-2.93Z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m20.15,11.96c.06,0,.07-.03.05-.08l-.67-1.53c-.01-.06-.07-.06-.09,0l-.66,1.53c-.01.05,0,.08.06.08h1.31Zm-2.83,1.46c-.08,0-.14-.03-.18-.09-.04-.06-.04-.12-.01-.2l1.66-3.73c.03-.07.08-.1.15-.1h1.1c.07,0,.12.03.15.1l1.68,3.73c.06.15-.02.29-.19.29h-.74c-.07,0-.12-.04-.15-.11l-.2-.45s-.04-.05-.08-.05h-2.02s-.07.02-.08.05l-.19.45c-.03.07-.08.11-.15.11h-.74Z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m26.92,9.3c.11,0,.18.07.18.18v3.75c0,.11-.08.18-.18.18h-.81c-.05,0-.09-.02-.12-.05l-2.13-2.46s-.09-.03-.09.03v2.31c0,.11-.08.18-.18.18h-.67c-.11,0-.18-.08-.18-.18v-3.75c0-.11.08-.18.18-.18h.81c.05,0,.09.02.12.05l2.12,2.46s.09.03.09-.04v-2.29c0-.11.08-.18.18-.18h.67Z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m32.71,12.31c.09.06.1.18.04.26-.49.64-1.24.96-2.26.96-1.61,0-2.42-.69-2.42-2.07v-.21c0-1.37.81-2.06,2.42-2.06,1.02,0,1.76.28,2.19.85.06.08.05.19-.03.25l-.51.43c-.1.09-.21.07-.32-.03-.31-.35-.76-.53-1.34-.53-.91,0-1.36.36-1.36,1.08v.23c0,.72.45,1.08,1.36,1.08.64,0,1.11-.19,1.4-.57.1-.11.2-.13.32-.04l.51.38Z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="white" d="m36.89,12.29c.26-.17.4-.44.4-.82v-.23c0-.72-.48-1.08-1.46-1.08-.41,0-.74.07-1.01.23-.28.16-.43.46-.43.85v.23c0,.38.13.66.4.82.26.17.61.25,1.05.25s.79-.08,1.06-.25m-3.56-.84v-.21c0-1.35.84-2.06,2.51-2.06s2.52.71,2.52,2.06v.21c0,1.36-.84,2.07-2.52,2.07s-2.51-.69-2.51-2.07"/>
                </svg>
            </div>
        </div>
        <div className="d-flex justify-content-center main mb-5">
            <div className="card">
                {props.children}
            </div>
        </div>
    </React.Fragment>
);
